import React, { useEffect, useState } from 'react'
import { Alert, Button, Col, Container, Form, Row, Table } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import { useGetSingleClientQuery, useUpdateClientMutation } from './clientsApiSlice'
import RecipeModal from './RecipeModal'
import EquipmentModal from './EquipmentModal'
import VisitModal from './VisitModal'
import NewShopModal from './NewShopModal'
import useAuth from '../../hooks/useAuth'

const Client = () => {

    const { id } = useParams()
    const { id: userId } = useAuth()

    const {
        data: client,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetSingleClientQuery(id, {
        pollingInterval: 15000,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true
    })

    const [updateClient] = useUpdateClientMutation()

    const [clientData, setClientData] = useState({ category: '' }) // State for data used in form
    const [isEditable, setIsEditable] = useState(false); // State for disabled edit form
    const [showSuccess, setShowSuccess] = useState(false); // State for success message

    useEffect(() => {
        if (isSuccess) {
            console.log(client)
            setClientData({
                name: client.entities[id].name,
                email: client.entities[id].email,
                contactName: client.entities[id].contactName,
                category: client.entities[id].category,
                checklistFrequency: client.entities[id].checklist_frequency,
                seminarFrequency: client.entities[id].seminar_frequency,
            });
        }
    }, [isSuccess]);

    let content

    if (isLoading) {
        content = (<p>Loading...</p>)
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setClientData({ ...clientData, [name]: value });
    };

    // Handle toggle between edit and save
    const handleEditToggle = () => {
        setIsEditable(!isEditable);
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        // Handle form submission logic here
        try {
            console.log('submitting... ')
            const updateData = { ...clientData, userId };
            await updateClient({ id, updateData }).unwrap();
            console.log("Client updated successfully!");
            setIsEditable(false)
            setShowSuccess(true)
            setTimeout(() => setShowSuccess(false), 5000);
        } catch (error) {
            console.error("Failed to update client:", error);
        }
    };

    content = (
        <>
            <Container>
                <div className='page-title-box d-sm-flex align-items-center justify-content-between'>
                    <h4>ΓΕΝΙΚΕΣ ΠΛΗΡΟΦΟΡΙΕΣ</h4>
                </div>
                <Row>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group className="mb-3">
                            <Form.Label>Επωνυμία</Form.Label>
                            <Form.Control
                                type="text"
                                name="name"
                                value={clientData.name || ""}
                                onChange={handleChange}
                                disabled={!isEditable}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                                type="email"
                                name="email"
                                value={clientData.email || ""}
                                onChange={handleChange}
                                disabled={!isEditable}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Κατηγορία</Form.Label>
                            <Form.Control
                                type="category"
                                name="category"
                                value={clientData.category || ""}
                                onChange={handleChange}
                                disabled={!isEditable}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Υπεύθυνος Επικοινωνίας</Form.Label>
                            <Form.Control
                                type="text"
                                name="contactName"
                                value={clientData.contactName || ""}
                                onChange={handleChange}
                                disabled={!isEditable}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Συχνότητα Checklist (Σε ημέρες)</Form.Label>
                            <Form.Control
                                type="text"
                                name="checklistFrequency"
                                value={clientData.checklistFrequency || ""}
                                onChange={handleChange}
                                disabled={!isEditable}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Συχνότητα Σεμιναρίων (Σε ημέρες)</Form.Label>
                            <Form.Control
                                type="text"
                                name="seminarFrequency"
                                value={clientData.seminarFrequency || ""}
                                onChange={handleChange}
                                disabled={!isEditable}
                            />
                        </Form.Group>

                        <Row className="justify-content-start">
                            {showSuccess && (
                                <Alert variant="success" onClose={() => setShowSuccess(false)} dismissible className="mb-3">
                                    Τα στοιχεία ενημερώθηκαν επιτυχώς!
                                </Alert>
                            )}
                            {/* Toggle buttons */}
                            {!isEditable ? (
                                <>
                                    <Col xs={12} md="auto" className="mb-2">
                                        <VisitModal clientId={id} />
                                    </Col>
                                    <Col xs={12} md="auto" className="mb-2">
                                        <NewShopModal clientId={id} />
                                    </Col>
                                    <Col xs={12} md="auto" className="mb-2">
                                        <Button variant="primary" className='primary-btn' disabled>
                                            Προσθήκη Checklist
                                        </Button>
                                    </Col>
                                    <Col xs={12} md="auto" className="mb-2">
                                        <Button variant="primary" className='primary-btn' onClick={handleEditToggle}>
                                            Επεξεργασία
                                        </Button>
                                    </Col>
                                </>
                            ) : (
                                <Col xs="auto">
                                    <Button variant="success" type='submit' className='primary-btn'>
                                        Αποθήκευση
                                    </Button>
                                </Col>
                            )}
                        </Row>
                    </Form>
                </Row>
                <div className='page-title-box d-sm-flex align-items-center justify-content-between'>
                    <h4>ΠΛΗΡΟΦΟΡΙΕΣ ΚΑΤΑΣΤΗΜΑΤΩΝ</h4>
                </div>

                <Table responsive hover className='table'>
                    <thead>
                        <tr>
                            <th>A/A</th>
                            <th>Όνομα Καταστήματος</th>
                            <th>Νομός</th>
                            <th>Πόλη</th>
                            <th>Συνταγή</th>
                            <th>Εξοπλισμός</th>
                        </tr>
                    </thead>
                    <tbody>
                        {isSuccess && client?.entities[id]?.shops[0]?.shop_name.length > 0 && client?.entities[id]?.shops.map((shop, index) => (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{shop.shop_name}</td>
                                <td>{shop.prefecture}</td>
                                <td>{shop.city}</td>
                                <td><RecipeModal recipes={shop.recipes} id={id} shopId={shop._id} /></td>
                                <td><EquipmentModal equipment={shop.equipment} id={id} shopId={shop._id} /></td>
                            </tr>
                        ))}
                    </tbody>
                </Table>

                <div className='page-title-box d-sm-flex align-items-center justify-content-between'>
                    <h4>ΙΣΤΟΡΙΚΟ ΕΠΙΚΟΙΝΩΝΙΑΣ</h4>
                </div>
                <Table responsive hover className='table'>
                    <thead>
                        <tr>
                            <th>A/A</th>
                            <th>Ημερομηνία</th>
                            <th>Πωλητής</th>
                            <th>Σχόλια</th>
                        </tr>
                    </thead>
                    <tbody>
                        {isSuccess && client?.entities[id]?.contact_history?.length > 0 ? (
                            client.entities[id].contact_history.map((visit, i) => (
                                <tr key={i}>
                                    <td>{i + 1}</td>
                                    <td>{new Date(visit.date).toLocaleString('el-GR', { day: 'numeric', month: 'short', year: 'numeric' })}</td>
                                    <td>{visit.user.username}</td>
                                    <td>{visit.comments}</td>
                                </tr>
                            ))
                        ) :
                            <tr>
                                <td colSpan={4}><i>Δεν υπάρχει ιστορικό επικοινωνίας</i></td>
                            </tr>
                        }
                    </tbody>
                </Table>

                <div className='page-title-box d-sm-flex align-items-center justify-content-between'>
                    <h4>ΙΣΤΟΡΙΚΟ CHECKLIST</h4>
                </div>
                <Table responsive hover className='table'>
                    <thead>
                        <tr>
                            <th>A/A</th>
                            <th>Όνομα Καταστήματος</th>
                            <th>Ημερομηνία</th>
                            <th>Τεχνικός</th>
                            <th>Αρχείο</th>
                        </tr>
                    </thead>
                    <tbody>
                    </tbody>
                </Table>
            </Container>
        </>
    )
    return content
}

export default Client