import React, { useEffect, useState } from 'react'
import useAuth from '../../hooks/useAuth';
import { useGetSchedulesByCategoryQuery, useRemoveEntryMutation, useUpdateEntryStatusMutation } from './userScheduleApiSlice';
import Schedule from '../../components/Schedule';
import { useLocation } from 'react-router-dom';

const UserScheduleList = () => {
  const categories = ['ΔΕΙΓΜΑΤΙΣΜΟΣ', 'ΕΠΙΣΚΕΨΗ', 'ΣΕΜΙΝΑΡΙΟ', 'CHECKLIST', 'ΑΛΛΑΓΗ ΕΞΟΠΛΙΣΜΟΥ', 'ΕΓΚΑΤΑΣΤΑΣΗ ΕΞΟΠΛΙΣΜΟΥ', 'ΧΑΡΤΟΓΡΑΦΗΣΗ', 'ΑΛΛΟ']
  const { id: currentUser } = useAuth
  const [formData, setFormData] = useState({
    categories,
    sorting: "asc",
    date: "",
    userId: currentUser,
  })

  const location = useLocation();
  const [successMsg, setSuccessMsg] = useState(location.state?.successMsg || false);
  const [showSuccessMsg, setShowSuccessMsg] = useState(false);

  const { data: scheduleData, isSuccess, refetch } = useGetSchedulesByCategoryQuery(formData)
  const [removeEntry, { isSuccess: removeSuccess }] = useRemoveEntryMutation()
  const [updateEntryStatus, { isLoading: statusUpdateLoading }] = useUpdateEntryStatusMutation();

  useEffect(() => {
    if (successMsg) {
      refetch()
      const timer = setTimeout(() => {
        setSuccessMsg(false); // Remove message after 5 seconds
      }, 5000);
      return () => clearTimeout(timer); // Cleanup timer when component unmounts
    }
  }, [successMsg, refetch]);

  const handleDelete = async (id) => {
    const isConfirmed = window.confirm("Είστε σίγουρος ότι θέλετε να διαγράψετε αυτή την καταχώρηση");
    if (isConfirmed) {
      try {
        await removeEntry({ id })
        setShowSuccessMsg(true)
      } catch (err) {
        console.error(err)
      }
      refetch()
    }
  };

  const handleUpdateStatus = async (entryId) => {
    try {
      await updateEntryStatus(entryId);
    } catch (error) {
      console.error("Error updating status:", error);
    }
  }

  return <Schedule
    addButtonText='Νέα Εργασία'
    title='ΠΡΟΓΡΑΜΜΑ'
    categories={categories}
    formData={formData} // Pass filter state
    setFormData={setFormData} // Allow Schedule to update filters
    scheduleData={scheduleData}
    isSuccess={isSuccess}
    handleUpdateStatus={handleUpdateStatus}
    handleDelete={handleDelete}
    removeSuccess={removeSuccess}
    showSuccessMsg={showSuccessMsg}
    successMsg={successMsg}
    statusUpdateLoading={statusUpdateLoading}
  />
}

export default UserScheduleList