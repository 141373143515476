import { createSelector, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../../app/api/apiSlice"

const userScheduleAdapter = createEntityAdapter({})
const initialState = userScheduleAdapter.getInitialState()

export const userScheduleApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getUserSchedule: builder.query({
            query: ({ user, date }) => {
                const params = new URLSearchParams();
                if (user) params.append("user", user);
                if (date) params.append("date", date);
                return `/user-schedule?${params.toString()}`;
            },
            validateStatus: (response, result) => {
                return response.status === 200 && !result.isError
            },
            transformResponse: responseData => {
                const loadedUserSchedule = responseData.map(schedule => {
                    schedule.id = schedule._id
                    return schedule
                })
                return userScheduleAdapter.setAll(initialState, loadedUserSchedule)
            },
            providesTags: (result, error, arg) => {
                if (result?.ids) {
                    return [
                        { type: 'userSchedule', id: 'LIST' },
                        ...result.ids.map(id => ({ type: 'userSchedule', id }))
                    ]
                } else return [{ type: 'userSchedule', id: 'LIST' }]
            }
        }),
        getSchedulesByCategory: builder.query({
            query: ({ category, categories, sorting, date, userId }) => {
                // Build the query string with provided parameters
                const params = new URLSearchParams();

                if (category) {
                    params.append('category', category)
                } else if (categories && categories.length > 0) {
                    categories.forEach((category) => {
                        params.append('categories', category);
                    });
                }

                if (sorting) params.append('sorting', sorting);
                if (date) params.append('date', date)
                if (userId) params.append('userId', userId)                

                // Build and return the query string with params
                return `/user-schedule/custom?${params.toString()}`;
            },
            validateStatus: (response) => response.status === 200, // ✅ Simplified validation
            transformResponse: (responseData) => {
                const loadedUserSchedule = responseData.map(({ _id, ...rest }) => ({
                    id: _id,
                    ...rest
                }));
                return userScheduleAdapter.setAll(initialState, loadedUserSchedule);
            }
        }),
        addToUserSchedule: builder.mutation({
            query: initialScheduleData => ({
                url: '/user-schedule',
                method: 'POST',
                body: {
                    ...initialScheduleData,
                }
            }),
            invalidatesTags: [
                { type: 'userSchedule', id: 'LIST' }
            ]
        }),
        removeEntry: builder.mutation({
            query: initialEntryData => ({
                url: '/user-schedule',
                method: 'DELETE',
                body: {
                    ...initialEntryData
                }
            }),
            invalidatesTags: ['userSchedule']
        }),
        updateEntryStatus: builder.mutation({
            query: (id) => ({
                url: `/user-schedule/${id}/completed`,
                method: "PATCH",
            }),
            invalidatesTags: ["userSchedule"], // Invalidate cache to refresh UI
        }),
        addMultipleEntries: builder.mutation({
            query: initialScheduleData => ({
                url: '/user-schedule/add-multiple-entries',
                method: 'POST',
                body: {
                    initialScheduleData,
                }
            }),
        })
    })
})

export const {
    useGetUserScheduleQuery,
    useGetSchedulesByCategoryQuery,
    useAddToUserScheduleMutation,
    useRemoveEntryMutation,
    useUpdateEntryStatusMutation,
    useAddMultipleEntriesMutation
} = userScheduleApiSlice

export const selectUserScheduleResult = userScheduleApiSlice.endpoints.getUserSchedule.select()

const selectUserScheduleData = createSelector(
    selectUserScheduleResult,
    userScheduleResult => userScheduleResult.data
)