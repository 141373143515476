import { createSelector, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../../app/api/apiSlice";

const shopNamesAdapter = createEntityAdapter({})
const initialState = shopNamesAdapter.getInitialState()

export const shopNamesApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getAllStoreNames: builder.query({
            query: () => '/clients/getStoreNames',
            validateStatus: (response, result) => {
                return response.status === 200 && !result.isError
            },
        }),
    })
})

export const {
    useGetAllStoreNamesQuery
} = shopNamesApiSlice