import { faCheck, faEye, faMinus, faPlusSquare, faPrint } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Form, Col, Container, Row, Alert, Table, Button, Modal } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import useUserList from '../hooks/useUserList'
import { useReactToPrint } from "react-to-print";
import { forwardRef, useEffect, useRef, useState } from 'react'

const Schedule = ({
    addButtonText,
    title,
    formData,
    setFormData, // Allow updates to filters
    categories,
    scheduleData,
    isSuccess,
    handleUpdateStatus,
    handleDelete,
    removeSuccess,
    showSuccessMsg,
    successMsg,
    statusUpdateLoading,
}) => {

    const contentRef = useRef(null);

    const [hideCompleted, setHideCompleted] = useState(true); // Toggle completed entries
    const onHideCompleted = () => setHideCompleted((prev) => !prev)

    const [showComments, setShowComments] = useState(false);
    const [selectedEntry, setSelectedEntry] = useState(null); // Store selected entry

    const handleShowComments = (entry) => {
        setSelectedEntry(entry); // Set the selected entry
        setShowComments(true);  // Open modal
    };

    const handleCloseComments = () => {
        setShowComments(false);
        setSelectedEntry(null); // Clear selected entry when closing
    };

    // Function to handle printing
    const handlePrint = () => {
        const printWindow = window.open('', '_blank', 'width=800,height=600');
        const content = contentRef.current?.innerHTML; // Get the content to print

        if (content) {
            printWindow.document.write(content); // Write the content to the window
            printWindow.document.write('</body></html>');
            printWindow.document.close(); // Close the document for rendering

            // Wait for the document to fully load, then trigger print
            printWindow.onload = () => {
                printWindow.print();
                printWindow.close(); // Close the print window after printing
            };
        }
    };

    const { userList, isLoading: usersLoading } = useUserList() // Get All users with username and id

    // Handle changes in filters
    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name === 'categories') {
            setFormData((prevFilters) => ({
                ...prevFilters,
                [name]: value ? value.split(',') : [],  // For multi-select, split by commas
            }))
        } else if (name === 'category' && value === 'all') {
            setFormData((prevFilters) => ({
                ...prevFilters,
                category: '',
                categories: categories
            }))
        }
        else {
            setFormData((prevFilters) => ({
                ...prevFilters,
                [name]: value,
            }));
        }
    };

    const options = { weekday: 'long', day: 'numeric', month: 'long', year: 'numeric' };
    const navigate = useNavigate()

    const handleClick = (event) => {
        event.preventDefault(); // Prevent default anchor link behavior

        // Use navigate to go to the target route and pass state
        navigate('/dashboard/user-schedule/new', {
            state: { categories }
        });
    }

    return (
        <>
            <Container fluid className="secondary-bar">
                <Container>
                    <Row className="align-items-center py-3">
                        <Col xs={12} md="auto">
                            <a href="" onClick={handleClick} className="link-style">
                                {addButtonText}
                            </a>
                        </Col>
                        <Col xs={12} md="auto">
                            <Link onClick={handlePrint} className="link-style">
                                <FontAwesomeIcon icon={faPrint} /> Εκτύπωση
                            </Link>
                        </Col>
                        <Col xs={12} md="auto">
                            <Form.Control type="date" name="date" value={formData.date} onChange={handleChange} />
                        </Col>
                        <Col xs={12} md="auto">
                            <Form.Group as={Col} xs={12} md={12} controlId="userId">
                                <Form.Control
                                    as='select'
                                    name="userId"
                                    value={formData.userId}
                                    onChange={handleChange}
                                >
                                    <option>Επιλογή Πωλητή</option>
                                    {!usersLoading && userList?.map((user, index) => (
                                        <option key={index} value={user.id}>{user.username}</option>
                                    ))}
                                </Form.Control >
                            </Form.Group>
                        </Col>
                        <Col xs={12} md="auto">
                            <Form.Group as={Col} xs={12} md={12} controlId="category">
                                <Form.Control
                                    as='select'
                                    name="category"
                                    value={formData.category}
                                    onChange={handleChange}
                                >
                                    <option value='all'>Επιλογή Κατηγορίας</option>
                                    {categories?.map((category, index) => (
                                        <option key={index} value={category}>{category}</option>
                                    ))}
                                </Form.Control >
                            </Form.Group>
                        </Col>
                        <Col xs={12} md="auto">
                            <Form.Group as={Col} xs={12} md={12} controlId="sorting">
                                <Form.Control
                                    as='select'
                                    name="sorting"
                                    value={formData.sorting}
                                    onChange={handleChange}
                                >
                                    <option value="asc">▲ Ημερομηνία</option>
                                    <option value="desc">▼ Ημερομηνία</option>
                                </Form.Control >
                            </Form.Group>
                        </Col>
                        <Col xs={12} md="auto">
                            <Form.Group>
                                <Form.Check // prettier-ignore
                                    type="switch"
                                    id="showCompleted"
                                    name="showCompleted"
                                    label="Ολοκληρωμένα"
                                    value={hideCompleted}
                                    onChange={onHideCompleted}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                </Container>
            </Container>

            <Container>

                <div className='page-title-box d-sm-flex align-items-center justify-content-between'>
                    <h4>{title}</h4>
                </div>
                <Row className="mt-2">
                    {removeSuccess && showSuccessMsg && (
                        <Alert>
                            Η καταχώρηση διαγράφηκε
                        </Alert>
                    )}
                    {successMsg && (
                        <Alert variant="success" dismissible className="mb-3">
                            Το πρόγραμμα καταχωρήθηκε!
                        </Alert>
                    )}
                    <Col sm={12}>
                        <Table responsive hover className='table'>
                            <thead>
                                <tr>
                                    <td>#</td>
                                    <td>Ημερομηνία</td>
                                    <td>Κατάστημα</td>
                                    <td>Κατηγορία</td>
                                    <td>Πωλητής</td>
                                    <td>Κατάσταση</td>
                                    <td>Ενέργειες</td>
                                </tr>
                            </thead>
                            <tbody>

                                {isSuccess && scheduleData.ids
                                    .map(id => scheduleData.entities[id])
                                    .filter(entry => {
                                        // Normalize entry date to YYYY-MM-DD format (UTC)
                                        const entryDate = new Date(entry.date);
                                        entryDate.setUTCHours(0, 0, 0, 0); // Normalize to UTC midnight to avoid time zone issues
                                        const entryDateStr = entryDate.toISOString().split('T')[0]; // Get YYYY-MM-DD format

                                        // Get today's date and normalize to YYYY-MM-DD format (UTC)
                                        const today = new Date();
                                        today.setUTCHours(0, 0, 0, 0); // Normalize to UTC midnight to avoid time zone issues
                                        const todayStr = today.toISOString().split('T')[0]; // Get YYYY-MM-DD format

                                        // Check if formData.date is set (and we only need to match entries to that date)
                                        const matchesDateFilter = formData.date
                                            ? entryDateStr === formData.date // Only show entries that match the form date
                                            : entryDateStr >= todayStr; // Show today and future entries by default if no date filter

                                        // Only show non-completed entries if hideCompleted is set to true
                                        const matchesCompletionFilter = hideCompleted ? !entry.completed : true;

                                        // Check if the entry is a future or today's entry (to hide past dates)
                                        const isTodayOrFuture = entryDateStr >= todayStr;

                                        console.log('entryDateStr:', entryDateStr, 'todayStr:', todayStr, 'isTodayOrFuture:', isTodayOrFuture);

                                        // **Key Change**: Allow entries to appear if they match the form's date (even if it's in the past)
                                        return matchesDateFilter && matchesCompletionFilter;
                                    })
                                    .map((entry, index) => (
                                        <tr key={entry.id}>
                                            <td>{index + 1}</td>
                                            <td>{new Date(entry.date).toLocaleDateString('el-GR', options)}</td>
                                            <td>{entry?.storeName}</td>
                                            <td>{entry?.entryCategory}</td>
                                            <td>{entry?.userId?.username}</td>
                                            <td>{entry.completed ? <b>Ολοκληρώθηκε</b> : "Δεν Ολοκληρώθηκε"}</td>
                                            <td>
                                                <Button
                                                    variant={entry.completed ? "secondary" : "success"}
                                                    size='sm'
                                                    style={{ marginLeft: "10px" }}
                                                    onClick={!entry.completed ? () => handleUpdateStatus(entry.id) : undefined}
                                                    disabled={entry.completed || statusUpdateLoading}
                                                >
                                                    {statusUpdateLoading && !entry.completed ? "Updating..." : <FontAwesomeIcon icon={faCheck} />}
                                                </Button>
                                                <Button
                                                    variant={"primary"}
                                                    size='sm'
                                                    style={{ marginLeft: "10px" }}
                                                    onClick={() => handleShowComments(entry)}
                                                    disabled={statusUpdateLoading}
                                                >
                                                    {statusUpdateLoading && !entry.completed ? "Updating..." : <FontAwesomeIcon icon={faEye} />}
                                                </Button>
                                                <Button
                                                    variant={entry.completed ? "secondary" : "danger"}
                                                    size='sm'
                                                    style={{ marginLeft: "10px", border: "none !important" }}
                                                    onClick={!entry.completed ? () => handleDelete(entry.id) : undefined}
                                                    disabled={entry.completed || statusUpdateLoading}
                                                >
                                                    {statusUpdateLoading && !entry.completed ? "Updating..." : <FontAwesomeIcon icon={faMinus} />}
                                                </Button>
                                            </td>
                                        </tr>
                                    )
                                    )}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Container>
            {/* Print content */}
            <div ref={contentRef} style={{ display: 'none' }}>
                <Table bordered>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Ημερομηνία</th>
                            <th>Κατάστημα</th>
                            <th>Πωλητής</th>
                            <th>Κατάσταση</th>
                        </tr>
                    </thead>
                    <tbody>
                        {isSuccess &&
                            scheduleData.ids.map((id, index) => {
                                const entry = scheduleData.entities[id];
                                return (
                                    <tr key={entry.id}>
                                        <td>{index + 1}</td>
                                        <td>{new Date(entry.date).toLocaleDateString('el-GR')}</td>
                                        <td>{entry.storeName}</td>
                                        <td>{entry.userId?.username}</td>
                                        <td>{entry.completed ? <b>Ολοκληρώθηκε</b> : 'Δεν Ολοκληρώθηκε'}</td>
                                    </tr>
                                );
                            })}
                    </tbody>
                </Table>
            </div>
            <Container>
                <Modal show={showComments} onHide={handleCloseComments} centered size='xl'>
                    <Modal.Header closeButton>
                        {selectedEntry ? (
                            <>
                                < Modal.Title>{selectedEntry.entryCategory.charAt(0) + selectedEntry.entryCategory.slice(1).toLowerCase()} στο κατάστημα {selectedEntry.storeName} </Modal.Title>
                            </>
                        ) : (
                            <>No Data</>
                        )}

                    </Modal.Header>
                    <Modal.Body>
                        {selectedEntry ? (
                            <>
                                <Row>
                                    <Col xs={12} md={2}>
                                        <Row>
                                            <strong>Ημερομηνία</strong>
                                        </Row>
                                        {new Date(selectedEntry.date).toLocaleDateString('el-GR')}
                                    </Col>
                                    <Col xs={12} md={2}>
                                        <Row>
                                            <strong>Κατάστημα</strong>
                                        </Row>
                                        {selectedEntry.storeName}
                                    </Col>
                                    <Col xs={12} md={2}>
                                        <Row>
                                            <strong>Πωλητής</strong>
                                        </Row>
                                        {selectedEntry.userId.username}
                                    </Col>
                                    <Col xs={12} md={2}>
                                        <Row>
                                            <strong>Κατηγορία</strong>
                                        </Row>
                                        {selectedEntry.entryCategory}
                                    </Col>
                                    <Col xs={12} md={2}>
                                        <Row>
                                            <strong>Κατάσταση</strong>
                                        </Row>
                                        {selectedEntry.completed ? <b>Ολοκληρώθηκε</b> : 'Δεν Ολοκληρώθηκε'}
                                    </Col>
                                </Row>

                                <Col className='mt-4'>
                                    <strong>Σχόλια:</strong> {selectedEntry.comments || "Δεν υπάρχουν σχόλια"}

                                </Col>

                            </>
                        ) : (
                            <>Δεν υπάρχουν δεδομένα.</>
                        )}
                    </Modal.Body>
                </Modal >
            </Container>

        </>
    )
}


export default Schedule