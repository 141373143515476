import React, { useEffect, useState } from 'react'
import useAuth from '../../hooks/useAuth';
import { Alert, Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { useAddShopMutation } from './clientsApiSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus } from '@fortawesome/free-solid-svg-icons';

const NewShopModal = ({ clientId }) => {
    const [show, setShow] = useState(false);
    const { id } = useAuth()
    const [showSuccessMsg, setShowSuccessMsg] = useState(false)

    const [addShop, { isSuccess }] = useAddShopMutation()

    const [formData, setFormData] = useState({
        shop_name: '',
        city: '',
        prefecture: '',
        recipes: [{
            coffee: '',
            doseIn: '',
            doseOut: '',
            timeSec: ''
        }],
        equipment: [{
            type: '',
            brand: '',
            model: '',
            serial_number: ''
        }]
    });

    // Handle opening and closing of the modal
    const handleShow = () => setShow(true);
    const handleClose = () => {
        setShow(false);
        setShowSuccessMsg(false)
    }

    // Handle form input changes
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        const newFormData = { ...formData }

        newFormData[name] = value

        setFormData(newFormData)
    };

    const removeEquipment = (equipmentIndex) => {
        setFormData((prevState) => ({
            ...prevState,
            equipment: prevState.equipment.filter((_, i) => i !== equipmentIndex),
        }));
    };


    const addEquipment = () => {
        setFormData((prevState) => ({
            ...prevState,
            equipment: [
                ...prevState.equipment,
                { type: '', brand: '', model: '', serial_number: '' }, // Empty equipment template
            ],
        }));
    };


    const addRecipe = () => {
        setFormData((prevState) => ({
            ...prevState,
            recipes: [
                ...prevState.recipes,
                { coffee: '', doseIn: '', doseOut: '', timeSec: '' }, // Empty recipe template
            ],
        }));
    };

    // Handle recipe-specific changes
    const handleRecipeChange = (e, recipeIndex, field) => {
        const { value } = e.target;
        const recipes = [...formData.recipes];

        // Update the recipe field for the given shop and recipe
        recipes[recipeIndex][field] = value;

        setFormData({ ...formData, recipes });
    };

    // Handle equipment-specific changes
    const handleEquipmentChange = (e, equipmentIndex, field) => {
        const { value } = e.target;
        const equipment = [...formData.equipment];

        // Update the recipe field for the given shop and recipe
        equipment[equipmentIndex][field] = value;

        setFormData({ ...formData, equipment });
    };

    const equipmentTypes = ['Grinder', 'Espresso Machine']

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        e.stopPropagation(); // Prevent the event from bubbling up to the parent form
        // Handle form submission logic here
        try {
            console.log('submitting... ')
            const updateData = { ...formData };
            await addShop({ clientId, updateData }).unwrap();
            console.log("Client updated successfully!");
            setShowSuccessMsg(true)
        } catch (error) {
            console.error("Failed to update client:", error);
        }
    };

    // Reset form
    const handleReset = () => {
        setShowSuccessMsg(false)
    };

    useEffect(() => {
        if (isSuccess) {
            setFormData({
                shop_name: '',
                city: '',
                prefecture: '',
                recipes: [{
                    coffee: '',
                    doseIn: '',
                    doseOut: '',
                    timeSec: ''
                }],
                equipment: [{
                    type: '',
                    brand: '',
                    model: '',
                    serial_number: ''
                }]
            })
        }
    }, [setFormData])


    return (
        <>
            {/* Button that triggers the modal */}
            <Button className="primary-btn" onClick={handleShow}>
                Προσθήκη Καταστήματος
            </Button>

            {/* Modal */}
            <Modal show={show} onHide={handleClose} size="xl">
                <Modal.Header closeButton>
                    <Modal.Title>Προσθήκη Καταστήματος</Modal.Title>
                </Modal.Header>
                {/* If the request is successful, show success message */}
                {isSuccess && showSuccessMsg ? (
                    <>
                        {/* If form is submitted, show the success message*/}
                        <Modal.Body>
                            <Alert variant="success">
                                Τα στοιχεία καταστήματος καταχωρήθηκαν επιτυχώς!

                            </Alert>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button className='primary-btn' onClick={handleReset}>Προσθήκη Καταστήματος</Button>
                            <Button className='cancel-btn' onClick={handleClose}>Κλείσιμο</Button>
                        </Modal.Footer>
                    </>
                ) : (
                    <Form onSubmit={handleSubmit}>
                        <Modal.Body>
                            <div className="mb-4">
                                <h5>Κατάστημα</h5>
                                <Form.Group className="mb-3">
                                    <Form.Label>Όνομα Καταστήματος</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name='shop_name'
                                        value={formData.shop_name}
                                        onChange={handleInputChange}
                                        required
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label>Πόλη</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name='city'
                                        value={formData.city}
                                        onChange={handleInputChange}
                                        required
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label>Νομός</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name='prefecture'
                                        value={formData.prefecture}
                                        onChange={handleInputChange}
                                        required
                                    />
                                </Form.Group>

                                <h6>Συνταγές</h6>
                                {formData?.recipes?.map((recipe, recipeIndex) => (
                                    <div key={recipeIndex}>
                                        <Row>
                                            <Col>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Καφές</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        value={recipe.coffee}
                                                        onChange={(e) => handleRecipeChange(e, recipeIndex, 'coffee')}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Dose In</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        value={recipe.doseIn}
                                                        onChange={(e) => handleRecipeChange(e, recipeIndex, 'doseIn')}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Dose Out</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        value={recipe.doseOut}
                                                        onChange={(e) => handleRecipeChange(e, recipeIndex, 'doseOut')}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Time (Sec)</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        value={recipe.timeSec}
                                                        onChange={(e) => handleRecipeChange(e, recipeIndex, 'timeSec')}
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </div>
                                ))}

                                <Button
                                    onClick={addRecipe}
                                    className="primary-btn"
                                >
                                    Προσθήκη Συνταγής
                                </Button>

                                <h6>Equipment</h6>
                                {formData?.equipment?.map((equip, equipmentIndex) => (
                                    <div key={equipmentIndex} className="mb-3">
                                        <Row>
                                            <Col>
                                                <Form.Group>
                                                    <Form.Label>Type</Form.Label>
                                                    <Form.Select
                                                        value={equip.type}
                                                        onChange={(e) => handleEquipmentChange(e, equipmentIndex, 'type')}
                                                    >
                                                        <option value="">Select Type</option>
                                                        {equipmentTypes.map((type) => (
                                                            <option key={type} value={type}>
                                                                {type}
                                                            </option>
                                                        ))}
                                                    </Form.Select>
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group>
                                                    <Form.Label>Brand</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        value={equip.brand}
                                                        onChange={(e) => handleEquipmentChange(e, equipmentIndex, 'brand')}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group>
                                                    <Form.Label>Model</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        value={equip.model}
                                                        onChange={(e) => handleEquipmentChange(e, equipmentIndex, 'model')}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group>
                                                    <Form.Label>Serial Number</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        value={equip.serial_number}
                                                        onChange={(e) => handleEquipmentChange(e, equipmentIndex, 'serial_number',)}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col className="d-flex align-items-end">
                                                <Button
                                                    className='cancel-btn'
                                                    onClick={() => removeEquipment(equipmentIndex)}
                                                >
                                                    <FontAwesomeIcon icon={faMinus} />
                                                </Button>
                                            </Col>
                                        </Row>
                                    </div>
                                ))}

                                <Button
                                    onClick={addEquipment}
                                    className='primary-btn'
                                >
                                    Προσθήκη Εξοπλισμού
                                </Button>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                                variant="secondary"
                                className="cancel-btn"
                                onClick={handleClose}
                            >
                                Κλείσιμο
                            </Button>
                            <Button type="submit" className="primary-btn">
                                Αποθήκευση
                            </Button>
                        </Modal.Footer>
                    </Form>
                )}
            </Modal>
        </>
    );
}

export default NewShopModal