import React, { useState } from 'react'
import { Button, Modal, Table } from 'react-bootstrap'
import SamplingForm from './forms/SamplingForm'

const SamplingsList = ({id, date, storeName, samplings}) => {
    const [show, setShow] = useState(false)

    const handleClose = () => {
        setShow(false)
    }

    const handleOpen = () => {
        setShow(true)
    }

    return (
        <>
            <Button className="primary-btn w-100" onClick={handleOpen}>
                {new Date(date).toLocaleString('el-GR', { day: 'numeric', month: 'short', year: 'numeric' })}
            </Button>

            <Modal show={show} onHide={handleClose} centered size='xl'>
                <Modal.Header closeButton>
                    <Modal.Title className="card-header">Ιστορικό Δειγματισμών για το Κατάστημα: {storeName}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Table striped hover responsive>
                        <thead>
                            <tr>
                                <th>Ημερομηνία</th>
                                <th>Πωλητής</th>
                                <th>Καφές Δειγματισμού</th>
                                <th>Σχόλια</th>
                            </tr>
                        </thead>
                        <tbody>
                            {samplings.map((sampling) => (
                                <tr key={sampling._id}>
                                    <td>{new Date(sampling.samplingDate).toLocaleString('el-GR', { day: 'numeric', month: 'long', year: 'numeric' })}</td>
                                    <td>{sampling.userID?.username}</td>
                                    <td>{sampling.coffee}</td>
                                    <td>{sampling.comments}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Modal.Body>
                <Modal.Footer>
                <div className='d-flex flex-column flex-md-row'>
                    <Button className='cancel-btn me-md-2 mb-2 mb-md-0' onClick={handleClose}>
                        Κλείσιμο
                    </Button>
                    <SamplingForm id={id} storeName={storeName}/>
                </div>
                </Modal.Footer>
            </Modal >
        </>
    )
}

export default SamplingsList