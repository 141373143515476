import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { Alert, Button, Col, Container, Row, Tab, Table } from 'react-bootstrap'
import { useLocation, useNavigate } from 'react-router-dom'
import { useGetClientsQuery } from './clientsApiSlice'
import TableContent from '../../components/TableContent'

const ClientList = () => {
  const location = useLocation();
  const [successMsg, setSuccessMsg] = useState(location.state?.successMsg || false);
  const [formData, setFormData] = useState({
    category: '',
    sorting: "asc"
  })

  const [searchQuery, setSearchQuery] = useState("")
  const {
    data: clients,
    isLoading,
    isSuccess,
    isError,
    error,
    refetch
  } = useGetClientsQuery(formData, {
    pollingInterval: 60000,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true
  })

  useEffect(() => {
    if (successMsg) {
      refetch()
      const timer = setTimeout(() => {
        setSuccessMsg(false); // Remove message after 5 seconds
      }, 5000);

      return () => clearTimeout(timer); // Cleanup timer when component unmounts
    }
  }, [successMsg]);

  const navigate = useNavigate()

  const handleFormOpen = () => {
    navigate('./add')
  }

  const navigateToClientPage = (id) => {
    navigate(`./${id}`)
  }

  return <TableContent
    addButtonText='Προσθήκη Πελάτη'
    title='ΚΑΤΑΓΡΑΦΗ ΚΑΙ ΔΙΑΧΕΙΡΙΣΗ ΠΕΛΑΤΩΝ'
    tableHeaders={['ΕΠΩΝΥΜΙΑ', 'ΚΑΤΗΓΟΡΙΑ', 'ΑΡΙΘΜΟΣ ΚΑΤΑΣΤΗΜΑΤΩΝ', 'ΕΠΙΚΟΙΝΩΝΙΑ', 'CHECKLIST']}
    tableData={clients}
    isSuccess={isSuccess}
    clientPage={navigateToClientPage}
    successMsg={successMsg}
    formData={formData} // Pass filter state
    setFormData={setFormData} // Allow Schedule to update filters
    categories={['ΑΘΗΝΑ', 'ΘΕΣ/ΚΗ', 'ΚΑΤΕΡΙΝΗ', 'ΚΡΗΤΗ', 'ΣΕΡΡΕΣ']}
    searchQuery={searchQuery}
    setSearchQuery={setSearchQuery}
  />
}

export default ClientList