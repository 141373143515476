import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Form, Row } from 'react-bootstrap'
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useAddMultipleEntriesMutation } from '../Schedules/userScheduleApiSlice'
import { useGetAllStoreNamesQuery } from '../clients/shopNamesApiSlice'
import useUserList from '../../hooks/useUserList'
import useAuth from '../../hooks/useAuth'
import { useLocation, useNavigate } from 'react-router-dom'
import useStoreNames from '../../hooks/useStoreNames'

const ScheduleEntryForm = () => {

    const [addMultipleEntries, { isSuccess }] = useAddMultipleEntriesMutation()

    const [formData, setFormData] = useState({ date: '', userId: '', storeNames: [], entryCategory: '' })
    const [nextFormData, setNextFormData] = useState([])
    const [storeNames, setStoreNames] = useState([])
    const [currentStep, setCurrentStep] = useState(1); // Step 1 initially
    const { id: currentUser } = useAuth()
    const navigate = useNavigate()
    const location = useLocation();
    const categories = location.state?.categories

    const { userList, isLoading: usersLoading } = useUserList() // Get All users with username and id
    const { storeList } = useStoreNames()
    const { data: getStoreNames, isSuccess: storeNameSuccess } = useGetAllStoreNamesQuery() // Get all Store Names from clients

    useEffect(() => {
        if (storeNameSuccess) {
            const sortedStoreNames = [...getStoreNames].sort((a, b) => a.localeCompare(b));  // Sorting alphabetically
            setStoreNames(sortedStoreNames)
        }
    }, [storeNameSuccess, getStoreNames])

    const handleChange = (e) => {
        const { name, value, type, selectedOptions } = e.target;

        // If it's a multi-select input, we handle the selected options
        if (type === 'select-multiple') {
            const selectedValues = Array.from(selectedOptions, option => option.value);
            setFormData({
                ...formData,
                [name]: selectedValues,  // Store the array of selected options
            });
        } else {
            setFormData({
                ...formData,
                [name]: value,  // For non-multiple select inputs, just update the value
            });
        }
    };

    const handleNextFormChange = (index, event) => {
        const { name, value } = event.target;
        // Create a copy of the existing array
        const updatedFormData = [...nextFormData];
        // Ensure the index exists before modifying it
        if (!updatedFormData[index]) {
            updatedFormData[index] = {};
        }
        // Update the specific field
        updatedFormData[index][name] = value;
        // Update state
        setNextFormData(updatedFormData);
    };


    // Handle form submission and move to the next step
    const handleNext = (e) => {
        e.preventDefault();
        // You could validate the form data here if needed

        // Create an array of objects
        const currentFormData = formData.storeNames.map(item => ({
            storeName: item,
            date: formData.date,
            userId: formData.userId,
            entryCategory: formData.entryCategory,
            updatedBy: currentUser
        }))

        setNextFormData(currentFormData)
        setCurrentStep(2);  // Move to the next step
    };

    const handlePrev = () => setCurrentStep(1)

    const handleRemoveStore = (index) => {
        const updatedFormData = [...nextFormData];
        updatedFormData.splice(index, 1);
        setNextFormData(updatedFormData)
    }

    const handleAddStore = () => {
        setNextFormData([...nextFormData, { storeName: '', date: '', userId: '' }]);
    };

    const handleSubmit = async (e) => {
        e.preventDefault()
        try {

            if (nextFormData.length === 0) {
                console.warn("No data to submit");
                return;
            }

            await addMultipleEntries(nextFormData)
        } catch (err) {
            console.log(err.message)
        }
    }

    useEffect(() => {
        if (isSuccess) {
            navigate("../", { state: { successMsg: true } })
        }
    }, [isSuccess, navigate])
    return (
        <>
            <Container>
                <h5 style={{ margin: '10px' }}>{currentStep === 1 ? "Βήμα 1ο: Επιλογή Καταστημάτων" : "Βήμα 2ο: Επαλήθευση Λίστας"}</h5>

                {currentStep === 1 && (
                    <Form onSubmit={handleNext}>
                        <Row>
                            <Form.Group as={Col} xs={12} md={12} controlId="date">
                                <Form.Label>Ημερομηνία</Form.Label>
                                <Form.Control
                                    type="date"
                                    name="date"
                                    value={formData.date}
                                    onChange={handleChange}
                                    required
                                />
                            </Form.Group>
                            <Form.Group as={Col} xs={12} md={12} controlId="userId">
                                <Form.Label>Επιλογή Πωλητή</Form.Label>
                                <Form.Control
                                    as='select'
                                    name="userId"
                                    value={formData.userId}
                                    onChange={handleChange}
                                    required
                                >
                                    <option>Επιλογή Πωλητή</option>
                                    {!usersLoading && userList?.map((user, index) => (
                                        <option key={index} value={user.id}>{user.username}</option>
                                    ))}
                                </Form.Control >
                            </Form.Group>
                            <Form.Group as={Col} xs={12} md={12} controlId="entryCategory">
                                <Form.Label>Επιλογή Κατηγορίας</Form.Label>
                                <Form.Control
                                    as='select'
                                    name="entryCategory"
                                    value={formData.entryCategory}
                                    onChange={handleChange}
                                >
                                    <option>Επιλογή Κατηγορίας</option>
                                    {categories?.map((category, index) => (
                                        <option key={index} value={category}>{category}</option>
                                    ))}

                                </Form.Control >
                            </Form.Group>
                            <Form.Group controlId="storeNames">
                                <Form.Label>Καταστήματα</Form.Label>
                                <Form.Control
                                    as='select'
                                    name='storeNames'
                                    value={formData.storeNames}
                                    onChange={handleChange}
                                    style={{ height: '400px' }}
                                    multiple
                                    required
                                >
                                    {storeNames.map((storeName, index) => (
                                        <option key={index} value={storeName}>
                                            {storeName}
                                        </option>
                                    ))}
                                </Form.Control>
                            </Form.Group>

                            
                        </Row>

                        <Button className="secondary-btn" type='submit'>
                            Επόμενο Βήμα
                        </Button>
                    </Form>
                )}
                {currentStep === 2 && (
                    <>
                        <Form onSubmit={handleSubmit}>
                            {Array.isArray(nextFormData) && nextFormData.map((item, index) => (
                                <>
                                    <Row style={{ backgroundColor: 'rgb(255, 255, 255)', padding: '12px', borderBottom: '1px solid #5c5c5c', marginBottom: '10px' }}>

                                        <Col xs={12} md={3}>
                                            <Form.Group controlId="storeName">
                                                <Form.Control
                                                    as='select'
                                                    name='storeName'
                                                    value={nextFormData[index].storeName}
                                                    onChange={(e) => handleNextFormChange(index, e)}

                                                >
                                                    {storeNames.map((storeName, index) => (
                                                        <option key={index} value={storeName}>
                                                            {storeName}
                                                        </option>
                                                    ))}
                                                </Form.Control>
                                            </Form.Group>
                                        </Col>

                                        <Col xs={12} md={2}>
                                            <Form.Group controlId="storeName">

                                                <Form.Control
                                                    type="date"
                                                    name="date"
                                                    value={nextFormData[index].date}
                                                    onChange={(e) => handleNextFormChange(index, e)} />
                                            </Form.Group>
                                        </Col>

                                        <Col xs={12} md={3}>
                                            <Form.Group controlId="storeName">
                                                <Form.Control
                                                    as='select'
                                                    name="userId"
                                                    value={nextFormData[index].userId}
                                                    onChange={(e) => handleNextFormChange(index, e)}
                                                >
                                                    <option>Επιλογή Εκπαιδευτή</option>
                                                    {!usersLoading && userList?.map((user, index) => (
                                                        <option key={index} value={user.id}>{user.username}</option>
                                                    ))}
                                                </Form.Control >
                                            </Form.Group>
                                        </Col>
                                        <Form.Group as={Col} xs={12} md={3} controlId="entryCategory">
                                            <Form.Control
                                                as='select'
                                                name="entryCategory"
                                                value={nextFormData[index].entryCategory}
                                                onChange={(e) => handleNextFormChange(index, e)}
                                            >
                                                <option>Επιλογή Κατηγορίας</option>
                                                <option>ΕΠΙΣΚΕΨΗ</option>
                                                <option>ΑΛΛΑΓΗ ΕΞΟΠΛΙΣΜΟΥ</option>
                                                <option>ΕΓΚΑΤΑΣΤΑΣΗ ΕΞΟΠΛΙΣΜΟΥ</option>

                                            </Form.Control >
                                        </Form.Group>

                                        <Col xs={12} md={1}>
                                            <Button
                                                variant="danger"
                                                onClick={() => handleRemoveStore(index)}
                                            >
                                                <FontAwesomeIcon icon={faMinus} />
                                            </Button>
                                        </Col>

                                        <Col xs={12} md={12} className='mt-4'>
                                            <Form.Group as={Col} xs={12} md={12}>
                                                <Form.Control as='textarea' name='comments' value={nextFormData[index].comments}
                                                    onChange={(e) => handleNextFormChange(index, e)}
                                                    placeholder='Σχόλια'
                                                />
                                            </Form.Group>
                                        </Col>

                                    </Row>
                                </>
                            ))}
                            <Button className='primary-btn' type='submit'>Αποθήκευση Προγράμματος</Button>

                        </Form>

                        <Button className='secondary-btn' onClick={handlePrev}>
                            Προηγούμενο Βήμα
                        </Button>
                        <Button className="secondary-btn" onClick={handleAddStore}>
                            <FontAwesomeIcon icon={faPlus} /> Προσθήκη Καταστήματος
                        </Button>
                    </>
                )}
            </Container>
        </>
    )
}

export default ScheduleEntryForm