import { faEye, faPlus, faProjectDiagram, faSchool } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Col, Row, Container, Table, Button } from 'react-bootstrap'
import { useGeneratePdfMutation, useGetChecklistsQuery } from './checklistsApiSlice'

const ChecklistList = () => {
    const navigate = useNavigate()

    const {
        data: checklists,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetChecklistsQuery('checklist', {
        pollingInterval: 60000,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true
    })

    const [generatePdf, {
        isLoading: pdfLoading
    }] = useGeneratePdfMutation()

    const handleGenerate = async (e, id) => {
        e.preventDefault()
        try {
            // Trigger the mutation with the ID
            const blob = await generatePdf( id ).unwrap();

            // Create a URL for the Blob and open it in a new tab
            const url = window.URL.createObjectURL(blob);
            window.open(url, '_blank');

            // Clean up the URL object
            window.URL.revokeObjectURL(url);
        } catch (err) {
            // Handle errors appropriately
            if (err.status === 404) {
                console.error('Error: PDF not found on server. Please check the URL and route on the backend.');
            } else if (err.status === 400) {
                console.error('Error: Invalid ID provided. Please ensure the ID is correct.');
            } else {
                console.error('Error generating PDF:', err);
            }
        }
    }

    const handleButton = () => navigate('/dashboard/checklists/schedule');

    let content

    if (isLoading) content = <p>Loading...</p>

    if (isError) content = <p>{error?.data?.message}</p>

    if (isSuccess) {
        const { ids, entities } = checklists

        content = (
            <Container className='overflow-hidden'>
                <div className='d-flex justify-content-between mt-4'>

                    <Button className='primary-btn' type='button' onClick={handleButton}>
                        <FontAwesomeIcon icon={faProjectDiagram} /> Πρόγραμμα Checklist
                    </Button>
                </div>

                {/* Data Containers */}
                <Row className='mt-2'>
                    <i>Η Λειτουργία Checklist βρίσκεται υπό κατασκευή</i>
                </Row>
            </Container>
        )
    }


    return content
}

export default ChecklistList