import { useGetUsersQuery } from "../features/users/userApiSlice";

const useUserList = () => {
  const {
    data: users,
    isLoading,
    isSuccess,
    isError,
    error
  } = useGetUsersQuery('usersList');

  if (isLoading) return { isLoading, userList: [] };
  if (isError) return { isError, error, userList: [] };

  // Extract user IDs and usernames into an array
  const userList = isSuccess && !isLoading ? users.ids
    .map(user => ({ id: users.entities[user].id, username: users.entities[user].username }))
    .filter(user => user.username !== 'test_user' && user.username !== 'k.kontarakis')
    : [];

  return { userList, isLoading, isSuccess, isError, error };
};

export default useUserList;
