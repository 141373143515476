import React, { useEffect, useState } from 'react'
import { useGetSchedulesByCategoryQuery, useRemoveEntryMutation, useUpdateEntryStatusMutation } from './userScheduleApiSlice'
import { useLocation, useNavigate } from 'react-router-dom'
import Schedule from '../../components/Schedule'

const ChecklistSchedule = () => {

    // Filter state
    const [formData, setFormData] = useState({
        categories: ['CHECKLIST'],
        sorting: "asc",
        date: "",
        userId: "",
    });

    const { data: checklistScheduleData, isSuccess, refetch } = useGetSchedulesByCategoryQuery(formData)
    const [updateEntryStatus, { isLoading: statusUpdateLoading }] = useUpdateEntryStatusMutation();
    const [removeEntry, { isSuccess: removeSuccess }] = useRemoveEntryMutation()

    const location = useLocation();
    const [successMsg, setSuccessMsg] = useState(location.state?.successMsg || false);

    useEffect(() => {
        if (successMsg) {
            const timer = setTimeout(() => {
                setSuccessMsg(false); // Remove message after 5 seconds
            }, 5000);
            refetch()
            return () => clearTimeout(timer); // Cleanup timer when component unmounts
        }
    }, [successMsg]);

    const navigate = useNavigate()
    const [showSuccessMsg, setShowSuccessMsg] = useState(false)

    const groupedData = isSuccess && checklistScheduleData?.ids.reduce((acc, id) => {
        const schedule = checklistScheduleData.entities[id];
        const date = schedule.date;  // Assuming each schedule has a date field
        if (!acc[date]) {
            acc[date] = [];  // Create a new array for this date if it doesn't exist
        }
        acc[date].push(schedule);  // Add the schedule to the group for this date
        return acc;
    }, {});

    const options = { weekday: 'long', day: 'numeric', month: 'long', year: 'numeric' };

    const handleUpdateStatus = async (entryId) => {
        try {
            await updateEntryStatus(entryId);
        } catch (error) {
            console.error("Error updating status:", error);
        }
        refetch()
    }

    const handleDelete = async (id) => {
        const isConfirmed = window.confirm("Είστε σίγουρος ότι θέλετε να διαγράψετε αυτή την καταχώρηση");
        if (isConfirmed) {
            try {
                await removeEntry({ id })
                setShowSuccessMsg(true)
            } catch (err) {
                console.error(err)
            }
            refetch()
        }
    };

    return <Schedule
        addButtonText='Νέο Πρόγραμμα Checklist'
        title='ΠΡΟΓΡΑΜΜΑ CHECKLIST'
        categories={['CHECKLIST']}
        formData={formData} // Pass filter state
        setFormData={setFormData} // Allow Schedule to update filters
        scheduleData={checklistScheduleData}
        isSuccess={isSuccess}
        handleUpdateStatus={handleUpdateStatus}
        handleDelete={handleDelete}
        removeSuccess={removeSuccess}
        showSuccessMsg={showSuccessMsg}
        successMsg={successMsg}
        statusUpdateLoading={statusUpdateLoading}
    />
}

export default ChecklistSchedule