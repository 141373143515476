
import React, { useState } from 'react'
import { Col, Container, Row, Table } from 'react-bootstrap'
import { useGetCoffeeStoresQuery, useGetStoreSamplingsQuery, useGetStoreVisitsQuery } from '../coffeeStores/storesApiSlice'
import AddButton from './AddButton'
import { useGetChecklistsQuery } from '../checklists/checklistsApiSlice'
import { useGetUserScheduleQuery } from '../Schedules/userScheduleApiSlice'
import useAuth from '../../hooks/useAuth'

const Overview = () => {
    const [currentPage, setCurrentPage] = useState(1)
    const [searchQuery, setSearchQuery] = useState('')

    const { username } = useAuth()

    const {
        data: checklistData,
        isSuccess: checklistsSuccess
    } = useGetChecklistsQuery()

    const {
        data: visitData,
        isSuccess: visitSuccess
    } = useGetStoreVisitsQuery()
    const {
        data: samplingData,
        isSuccess: samplingSuccess
    } = useGetStoreSamplingsQuery()

    const {
        data: coffeeStores,
        isLoading,
        isSuccess,
        isError,
        error,
        refetch
    } = useGetCoffeeStoresQuery({ page: currentPage, limit: 10, search: searchQuery }, {
        pollingInterval: 15000,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true
    })

    const {
        data: scheduleData,
        isSuccess: scheduleSuccess,
    } = useGetUserScheduleQuery({
        user: username,    // 'user' parameter (username)
        date: new Date().toISOString().split('T')[0],  // If date is provided, use it, otherwise send undefined
    })

    const options = { weekday: 'long', day: 'numeric', month: 'long', year: 'numeric' };
    const formattedDate = new Date().toLocaleDateString('el-GR', options)

    return (
        <Container className='overflow-hidden'>
            <div className="d-flex flex-column flex-md-row justify-content-between align-items-start mt-4">
                <div className="mb-3 mb-md-0">
                    <h2>Επισκόπηση</h2>
                </div>
                <div className="w-100 w-md-auto mt-3 mt-md-0 d-flex justify-content-md-end">
                    <AddButton className="w-100" />
                </div>
            </div>




            {/* Data containers */}

            <div className='page-title-box d-sm-flex align-items-center justify-content-between'>
                <h4>Το πρόγραμμα μου για {formattedDate}</h4>
            </div>
            <Row className="mt-2">
                <Col sm={12}>
                    <Table responsive hover>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Τύπος</th>
                                <th>Κατάστημα</th>
                            </tr>
                        </thead>
                        <tbody>
                            {scheduleSuccess && scheduleData.ids.length > 0 ? (
                                scheduleData.ids.map((id, index) => (
                                    <tr key={id}>
                                        <td>{index + 1}</td>
                                        <td>{scheduleData.entities[id].entryCategory}</td>
                                        <td>{scheduleData.entities[id].storeName}</td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan={6}><i>Δεν υπάρχει τίποτα προγραμματισμένο για σήμερα</i></td>
                                </tr>
                            )}
                        </tbody>
                    </Table>

                </Col>
            </Row >

            <Row className='mt-2'>
                <Col sm={6} className='mt-2'>
                    <div className='content-wrapper'>
                        <div className='content-wrapper-header'>
                            Τελευταίες Επισκέψεις
                        </div>
                        <div className='content-data pt-4'>
                            <Table hover responsive>
                                <thead>
                                    <tr>
                                        <th>Ημερομηνία</th>
                                        <th>Κατάστημα</th>
                                        <th>Πόλη</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {visitSuccess && visitData?.ids?.map((id) => (
                                        <tr key={id}>
                                            <td>{new Date(visitData?.entities?.[id]?.visitDate).toLocaleString('el-GR', { day: 'numeric', month: 'long', year: 'numeric' })}</td>
                                            <td>{visitData?.entities?.[id]?.storeName}</td>
                                            <td>{visitData?.entities?.[id]?.city}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </Col>
                <Col sm={6} className='mt-2 '>
                    <div className='content-wrapper h-100'>
                        <div className='content-wrapper-header'>
                            Προγραμματισμένες Επισκέψεις
                        </div>
                        <div className='content-data pt-4'>
                            <Table hover responsive>
                                <thead>
                                    <tr>
                                        <th>Ημερομηνία</th>
                                        <th>Κατάστημα</th>
                                        <th>Τύπος</th>
                                    </tr>
                                </thead>
                                <tbody>
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </Col>
                <Col sm={6} className='mt-2'>
                    <div className='content-wrapper'>
                        <div className='content-wrapper-header'>
                            Τελευταίοι Δειγματισμοί
                        </div>
                        <div className='content-data pt-4'>
                            <Table hover responsive>
                                <thead>
                                    <tr>
                                        <th>Ημερομηνία</th>
                                        <th>Κατάστημα</th>
                                        <th>Πόλη</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {samplingSuccess && samplingData?.ids?.map((id) => (
                                        <tr key={id}>
                                            <td>{new Date(samplingData?.entities?.[id]?.samplingDate).toLocaleString('el-GR', { day: 'numeric', month: 'long', year: 'numeric' })}</td>
                                            <td>{samplingData?.entities?.[id]?.storeName}</td>
                                            <td>{samplingData?.entities?.[id]?.city}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </Col>
                <Col sm={6} className='mt-2'>
                    <div className='content-wrapper'>
                        <div className='content-wrapper-header'>
                            Τελευταία Checklists
                        </div>
                        <div className='content-data pt-4'>
                            <Table hover responsive>
                                <thead>
                                    <tr>
                                        <th>Ημερομηνία</th>
                                        <th>Κατάστημα</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {checklistsSuccess && checklistData?.ids?.map((id) => (
                                        <tr key={id}>
                                            <td>{new Date(checklistData?.entities?.[id]?.date).toLocaleString('el-GR', { day: 'numeric', month: 'long', year: 'numeric' })}</td>
                                            <td>{checklistData?.entities?.[id]?.name}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </Col>
                <Col sm={6} className='mt-2'>
                    <div className='content-wrapper'>
                        <div className='content-wrapper-header'>
                            Προγραμματισμένα Checklists
                        </div>
                        <div className='content-data pt-4'>
                            Δεν υπάρχουν Διαθέσιμα Δεδομένα
                        </div>
                    </div>
                </Col>
            </Row>
        </Container >
    )
}

export default Overview