import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { Button, Form, Modal } from 'react-bootstrap'
import { useEffect, useState } from 'react';
import { useAddToProjectsMutation } from './projectsApiSlice';
import useAuth from '../../hooks/useAuth';
import { useNavigate } from 'react-router-dom';

const AddToProjects = () => {

    const [addToProjects, {
        isLoading,
        isSuccess,
        isError,
        error
    }] = useAddToProjectsMutation()

    const navigate = useNavigate()

    const getCurrentDate = () => {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0'); // Add leading zero
        const day = String(today.getDate()).padStart(2, '0');        // Add leading zero
        return `${year}-${month}-${day}`;
    }

    const [show, setShow] = useState(false);
    const [projectForm, setProjectForm] = useState({ assignee: [], startDate: getCurrentDate(), status: 'Δεν ξεκίνησε', priority: 'Χαμηλή', category: 'Χωρίς Κατηγορία'})

    const onProjectFormChange = e => {
        const { name, value } = e.target
        // Clone the formData to avoid mutating state directly
        const updatedFormData = { ...projectForm }

        // If the field is "assignee", handle multiple values separated by commas
        if (name === "assignee") {
        // Split the value by commas and trim any excess whitespace
            updatedFormData[name] = value.split(',').map(item => item.trim());
        } else {
            updatedFormData[name] = value;
        }

        setProjectForm(updatedFormData)
    }

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [showErrorModal, setShowErrorModal] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    useEffect(() => {
        if (isSuccess) {
            setProjectForm({})
            navigate('/dashboard/schedule')
        }
    }, [isSuccess, navigate])

    const onAddNewTask = async (e) => {
        e.preventDefault()
        try {
            await addToProjects(projectForm)
        } catch (err) {
            const errorMessage = error.data?.message || 'An unexpected error occurred';
            setErrorMessage(errorMessage);
            setShowErrorModal(true);
        }
    }

    let content

    if (isError) {
        content = <p>{error?.data?.message}</p>
    }

    content = (
        <>
            <Button className='primary-btn' type="button" onClick={handleShow}>
                <FontAwesomeIcon icon={faPlus} /> Προσθήκη Project
            </Button>
            <Modal show={show} onHide={handleClose} centered size='xl'>

                <Modal.Header closeButton>
                    <Modal.Title>Προσθήκη Νέου Project</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Form>
                        <Form.Group className="mb-3" controlId="projectName">
                            <Form.Label>Όνομα Project(*)</Form.Label>
                            <Form.Control type="text" name='projectName' value={projectForm.projectName} onChange={onProjectFormChange} placeholder="Όνομα Project" required />
                            <Form.Text className="text-muted">
                                Το πεδίο είναι υποχρεωτικό
                            </Form.Text>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="projectDesc">
                            <Form.Label>Περιγραφή(*)</Form.Label>
                            <Form.Control
                                as="textarea"
                                name="projectDesc"
                                value={projectForm.projectDesc}
                                onChange={onProjectFormChange}
                                placeholder="Περιγραφή Project"
                                rows={5}
                                required
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="status">
                            <Form.Label>Κατάσταση</Form.Label>
                            <Form.Select name='status' value={projectForm.status} onChange={onProjectFormChange} placeholder="Κατάσταση" required>
                                <option key='notStarted' value='Δεν ξεκίνησε'>Δεν Ξεκίνησε</option>
                                <option key='inProgress' value='Σε εξέλιξη'>Σε εξέλιξη</option>
                                <option key='done' value='Ολοκληρώθηκε'>Ολοκληρώθηκε</option>
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="assignee">
                            <Form.Label>Μέλη(*)</Form.Label>
                            <Form.Control
                                list="assigneeOptions"
                                name="assignee"
                                value={projectForm.assignee}
                                onChange={onProjectFormChange}
                                placeholder=""
                            />
                            <datalist id="assigneeOptions">
                                <option value="g.varsamis" />
                                <option value="p.matziounis" />
                            </datalist>
                            <Form.Text className="text-muted">
                                Διαχωρείστε τα μέλη με κόμμα (,)
                            </Form.Text>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="startDate">
                            <Form.Label>Ημερομηνία Έναρξης(*)</Form.Label>
                            <Form.Control type="date" name='startDate' value={projectForm.startDate} onChange={onProjectFormChange} placeholder="Ημερομηνία Έναρξης" />
                            <Form.Text className="text-muted">

                            </Form.Text>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="dueDate">
                            <Form.Label>Καταληκτική Ημερομηνία(*)</Form.Label>
                            <Form.Control type="date" name='dueDate' value={projectForm.dueDate} onChange={onProjectFormChange} placeholder="Καταληκτική Ημερομηνία" />
                            <Form.Text className="text-muted">
                            </Form.Text>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="priority">
                            <Form.Label>Προτεραιότητα(*)</Form.Label>
                            <Form.Select name='priority' value={projectForm.priority} onChange={onProjectFormChange} >
                                <option key='low' value='Χαμηλή'>Χαμηλή</option>
                                <option key='medium' value='Μέτρια'>Μέτρια</option>
                                <option key='high' value='Υψηλή'>Υψηλή</option>
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="category">
                            <Form.Label>Κατηογρία(*)</Form.Label>
                            <Form.Select name='category' value={projectForm.category} onChange={onProjectFormChange} >
                                <option key='no-cat' value='Χωρίς Κατηγορία'>Χωρίς Κατηγορία</option>
                                <option key='marketing' value='Marketing'>Marketing</option>
                                <option key='sales' value='Πωλήσεις'>Πωλήσεις</option>
                                <option key='tech' value='Τεχνικό'>Τεχνικό</option>
                            </Form.Select>
                        </Form.Group>
                    </Form>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button type="submit" variant="primary" onClick={onAddNewTask}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )

    return content
}

export default AddToProjects