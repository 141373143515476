import { faCheck, faMinus, faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { Alert, Button, Col, Container, Row, Table } from 'react-bootstrap'
import { useGetSchedulesByCategoryQuery, useRemoveEntryMutation, useUpdateEntryStatusMutation } from '../Schedules/userScheduleApiSlice'
import { useLocation, useNavigate } from 'react-router-dom'
import Schedule from '../../components/Schedule'

const SeminarSchedule = () => {

    // Filter state
    const [formData, setFormData] = useState({
        categories: ['ΣΕΜΙΝΑΡΙΟ'],
        sorting: "asc",
        date: "",
        userId: "",
    });

    const { data: seminarScheduleData, isSuccess, refetch } = useGetSchedulesByCategoryQuery(formData)
    const [updateEntryStatus, { isLoading: statusUpdateLoading }] = useUpdateEntryStatusMutation();
    const [removeEntry, { isSuccess: removeSuccess }] = useRemoveEntryMutation()

    const location = useLocation();
    const [successMsg, setSuccessMsg] = useState(location.state?.successMsg || false);

    useEffect(() => {
        if (successMsg) {
            const timer = setTimeout(() => {
                setSuccessMsg(false); // Remove message after 5 seconds
            }, 5000);
            refetch()
            return () => clearTimeout(timer); // Cleanup timer when component unmounts
        }
    }, [successMsg]);

    const navigate = useNavigate()
    const [showSuccessMsg, setShowSuccessMsg] = useState(false)

    const options = { weekday: 'long', day: 'numeric', month: 'long', year: 'numeric' };

    const handleUpdateStatus = async (entryId) => {
        try {
            await updateEntryStatus(entryId);
        } catch (error) {
            console.error("Error updating status:", error);
        }
        refetch()
    }

    const handleDelete = async (id) => {
        const isConfirmed = window.confirm("Είστε σίγουρος ότι θέλετε να διαγράψετε αυτή την καταχώρηση");
        if (isConfirmed) {
            try {
                await removeEntry({ id })
                setShowSuccessMsg(true)
            } catch (err) {
                console.error(err)
            }
            refetch()
        }
    };

    return <Schedule
        addButtonText='Νέο Πρόγραμμα Σεμιναρίων'
        title='ΠΡΟΓΡΑΜΜΑ ΣΕΜΙΝΑΡΙΩΝ'
        categories={['ΣΕΜΙΝΑΡΙΟ']}
        formData={formData} // Pass filter state
        setFormData={setFormData} // Allow Schedule to update filters
        scheduleData={seminarScheduleData}
        isSuccess={isSuccess}
        handleUpdateStatus={handleUpdateStatus}
        handleDelete={handleDelete}
        removeSuccess={removeSuccess}
        showSuccessMsg={showSuccessMsg}
        successMsg={successMsg}
        statusUpdateLoading={statusUpdateLoading}
    />
}

export default SeminarSchedule