import React, { useEffect, useState } from 'react'
import { Alert, Button, Col, Form, Modal, Row } from 'react-bootstrap'
import { useGetUsersQuery } from '../users/userApiSlice';
import useAuth from '../../hooks/useAuth';
import { useAddMultipleEntriesMutation, useAddToUserScheduleMutation } from './userScheduleApiSlice';
import useUserList from '../../hooks/useUserList'


const AddToSchedule = ({ show, handleCloseView, scheduleEntryType, selectedStore }) => {

    const { id: currentUserId } = useAuth()
    const { userList, isLoading: usersLoading } = useUserList() // Get All users with username and id
    const [formData, setFormData] = useState({ date: '', userId: currentUserId, storeName: selectedStore?.storeTitle, entryCategory: scheduleEntryType })
    const [showSuccessMsg, setShowSuccessMsg] = useState(false)

    const handleClose = () => {
        handleCloseView();
        setShowSuccessMsg(false)
    }

    const [addMultipleEntries, {
        isLoading,
        isSuccess
    }] =     useAddMultipleEntriesMutation()

    const handleChange = (e) => {
        const { name, value } = e.target
        const updatedFormData = { ...formData, [name]: value };
        setFormData(updatedFormData)
    }

    const onAddToUserSchedule = async (e) => {
        e.preventDefault()
        try {
            const payload = { ...formData, updatedBy: currentUserId}
            await addMultipleEntries(payload)
            setShowSuccessMsg(true)
        } catch (err) {
            console.error(err)
        }
    }

    const options = { weekday: 'long', day: 'numeric', month: 'long', year: 'numeric' }

    return (
        <>
            <Modal show={show} onHide={handleCloseView} centered size='xl'>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {selectedStore.storeTitle}
                    </Modal.Title>
                </Modal.Header>

                {isSuccess && showSuccessMsg ? (
                    <>
                        {/* If form is submitted, show the success message*/}
                        <Modal.Body>
                            <Alert variant="success">
                                {scheduleEntryType == 'ΕΠΙΣΚΕΨΗ' ? 
                                    'Η επίσκψη προγραμματίστηκε' : 
                                    'Ο Δειγματισμός Προγραματίστηκε'}

                                <span>  </span>Για {new Date(formData.date).toLocaleDateString('el-GR', options)}
                            </Alert>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button className='cancel-btn' onClick={handleClose}>Κλείσιμο</Button>
                        </Modal.Footer>
                    </>
                ) : (

                    <Modal.Body>
                        <Form onSubmit={onAddToUserSchedule}>
                            <Row>
                                <Form.Group as={Col} xs={12} md={12} controlId="date">
                                    <Form.Label>Ημερομηνία</Form.Label>
                                    <Form.Control
                                        type="date"
                                        name="date"
                                        value={formData.date}
                                        onChange={handleChange}
                                        required
                                    />
                                </Form.Group>
                                <Form.Group as={Col} xs={12} md={12} controlId="userId">
                                    <Form.Label>Επιλογή Πωλητή</Form.Label>
                                    <Form.Control
                                        as='select'
                                        name="userId"
                                        value={formData.userId}
                                        onChange={handleChange}
                                        required
                                    >
                                        <option>Επιλογή Πωλητή</option>
                                        {!usersLoading && userList?.map((user, index) => (
                                            <option key={index} value={user.id}>{user.username}</option>
                                        ))}
                                    </Form.Control >
                                </Form.Group>
                                <Form.Group as={Col} xs={12} md={12} controlId="entryCategory">
                                    <Form.Label>Επιλογή Κατηγορίας</Form.Label>
                                    <Form.Control
                                        as='select'
                                        name="entryCategory"
                                        value={formData.entryCategory}
                                        onChange={handleChange}
                                        disabled
                                    >
                                        <option key='ΕΠΙΣΚΕΨΗ' value='ΕΠΙΣΚΕΨΗ'>ΕΠΙΣΚΕΨΗ</option>
                                        <option key='ΔΕΙΓΜΑΤΙΣΜΟΣ' value='ΔΕΙΓΜΑΤΙΣΜΟΣ'>ΔΕΙΓΜΑΤΙΣΜΟΣ</option>
                                    </Form.Control>
                                </Form.Group>
                                <Form.Group as={Col} xs={12} md={12}>
                                    <Form.Label>Σχόλια</Form.Label>
                                    <Form.Control type='text' name='comments' value={formData.comments} onChange={handleChange} required />
                                </Form.Group>
                            </Row>
                            <Button type='submit' className='primary-btn mt-3' disabled={isLoading}>Αποθήκευση</Button>
                        </Form>
                    </Modal.Body>
                )}
            </Modal>
        </>
    )
}

export default AddToSchedule