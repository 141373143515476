import { faCheck, faMinus, faPlusSquare, faPrint } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Form, Col, Container, Row, Alert, Table, Button } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'


const TableContent = ({
    addButtonText,
    title,
    tableHeaders,
    tableData,
    isSuccess,
    clientPage,
    successMsg,
    formData,
    setFormData,
    categories,
    searchQuery,
    setSearchQuery
}) => {

    // handleChange function to update the formData with the selected category
    const handleChange = (event) => {
        const { name, value } = event.target;
        // Update the formData with the selected category value
        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    // handleChange function to update the formData with the selected category
    const handleSearchQueryChange = (event) => {
        const { name, value } = event.target;
        // Update the formData with the selected category value
        setSearchQuery(value)
    };

    return (
        <>
            <Container fluid className="secondary-bar">
                <Container>
                    <Row className="align-items-center py-3">
                        <Col xs={12} md="auto">
                            <a href="./" className="link-style">
                                <FontAwesomeIcon icon={faPlusSquare} /> {addButtonText}
                            </a>
                        </Col>
                        <Col xs={12} md="auto">
                            <Link className="link-style">
                                <FontAwesomeIcon icon={faPrint} /> Εκτύπωση
                            </Link>
                        </Col>
                        <Col xs={12} md="auto">
                            <Form.Group as={Col} xs={12} md={12} controlId="searchQuery">
                                <Form.Control
                                    type='text'
                                    name="searchQuery"
                                    value={searchQuery}
                                    placeholder='Αναζήτηση Πελάτη'
                                    onChange={handleSearchQueryChange}
                                >
                                </Form.Control >
                            </Form.Group>
                        </Col>
                        <Col xs={12} md="auto">
                            <Form.Group as={Col} xs={12} md={12} controlId="category">
                                <Form.Control
                                    as='select'
                                    name="category"
                                    value={formData.category}
                                    onChange={handleChange}
                                >
                                    <option value='all'>Επιλογή Κατηγορίας</option>
                                    {categories?.map((category, index) => (
                                        <option key={index} value={category}>{category}</option>
                                    ))}
                                </Form.Control >
                            </Form.Group>
                        </Col>

                        <Col xs={12} md="auto">
                            <Form.Group as={Col} xs={12} md={12} controlId="sorting">
                                <Form.Control
                                    as='select'
                                    name="sorting"
                                    value={formData.sorting}
                                    onChange={handleChange}
                                >
                                    <option default value="asc">▲ Κατηγορία</option>
                                    <option value="desc">▼ Κατηγορία</option>
                                </Form.Control >
                            </Form.Group>
                        </Col>

                    </Row>
                </Container>
            </Container>
            <Container>

                <div className='page-title-box d-sm-flex align-items-center justify-content-between'>
                    <h4>{title}</h4>
                </div>
                <Row className="mt-2">
                    {successMsg && (
                        <Alert variant="success" dismissible className="mb-3">
                            Ο Νέος Πελάτες καταχωρήθηκε!
                        </Alert>
                    )}
                    <Col sm={12}>
                        <Table>
                            <thead>
                                <tr>
                                    <td>#</td>
                                    {tableHeaders.map(header => (
                                        <td>{header}</td>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {isSuccess &&
                                    tableData.ids.map((id, index) => {
                                        const entry = tableData.entities[id];

                                        // Filter based on search query, checking if the store name includes the search query (case insensitive)
                                        // If there's a search query, filter by storeName; otherwise, show all entries
                                        const matchesSearch =
                                            !searchQuery || entry?.name?.toLowerCase().includes(searchQuery.toLowerCase());

                                        // If matchesSearch is false, skip rendering this entry
                                        if (!matchesSearch) {
                                            return null; // This will skip rendering this row if it doesn't match search
                                        }

                                        // Get latest contact history entry
                                        const latestContact = entry.contact_history?.length > 0
                                            ? entry.contact_history.at(-1) // Get last item
                                            : null;

                                        return (
                                            <tr key={entry.id} style={{ cursor: 'pointer' }}>
                                                <td onClick={() => clientPage(id)}>{index + 1}</td>
                                                <td onClick={() => clientPage(id)}>{entry.name}</td>
                                                <td onClick={() => clientPage(id)}>{entry.category}</td>
                                                <td onClick={() => clientPage(id)}>{entry.shops.length}</td>
                                                {/* Display latest contact history entry */}
                                                <td onClick={() => clientPage(id)}>
                                                    {latestContact ? (
                                                        <>
                                                            <strong>{new Date(latestContact.date).toLocaleDateString('el-GR')}</strong><br />
                                                        </>
                                                    ) : (
                                                        <i>Δεν υπάρχουν διαθέσιμα δεδομένα</i>
                                                    )}
                                                </td>
                                                <td><i>Δεν υπάρχουν διαθέσιμα δεδομένα</i></td>
                                            </tr>
                                        );
                                    })}

                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Container>

        </>
    )
}

export default TableContent