import { createSelector, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../../app/api/apiSlice";

const clientsAdapter = createEntityAdapter({})
const initialState = clientsAdapter.getInitialState()

export const clientsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getClients: builder.query({
            query: ({ category, sorting }) => {
                const params = new URLSearchParams();

                if (category) params.append('category', category);
                if (sorting) params.append('sorting', sorting);

                // Build and return the query string with params
                return `/clients?${params.toString()}`;
            },
            validateStatus: (response, result) => {
                return response.status === 200 && !result.isError
            },
            transformResponse: responseData => {
                const loadedClients = responseData.map(client => {
                    client.id = client._id
                    return client
                })
                return clientsAdapter.setAll(initialState, loadedClients)
            },
            providesTags: (result, error, arg) => {
                if (result?.ids) {
                    return [
                        { type: 'clients', id: 'LIST' },
                        ...result.ids.map(id => ({ type: 'clients', id }))
                    ]
                } else return [{ type: 'clients', id: 'LIST' }]
            }
        }),
        getSingleClient: builder.query({
            query: (id) => `/clients/${id}`,
            validateStatus: (response, result) => {
                return response.status === 200 && !result.isError
            },
            transformResponse: responseData => {
                // Normalize a single client object
                responseData.id = responseData._id; // Normalize _id to id
                return clientsAdapter.setOne(initialState, responseData);
            },
            providesTags: (result, error, arg) => {
                if (result?.ids) {
                    return [
                        { type: 'clients', id: 'LIST' },
                        ...result.ids.map(id => ({ type: 'clients', id }))
                    ]
                } else return [{ type: 'clients', id: 'LIST' }]
            }
        }),
        createClients: builder.mutation({
            query: initialClientData => ({
                url: '/clients',
                method: 'POST',
                body: {
                    ...initialClientData,
                }
            }),
            invalidatesTags: [
                { type: 'clients', id: 'LIST' }
            ]
        }),
        updateClient: builder.mutation({
            query: ({ id, updateData }) => ({
                url: `/clients/${id}`,
                method: 'PATCH',
                body: {
                    ...updateData
                },
            }),
            invalidatesTags: (result, error, { id }) => [
                { type: 'clients', id },
                { type: 'clients', id: 'LIST' },
            ],
        }),
        updateRecipes: builder.mutation({
            query: ({ id, updateData }) => ({
                url: `/clients/${id}/recipes`,
                method: 'PATCH',
                body: {
                    ...updateData
                },
            }),
            invalidatesTags: (result, error, { id }) => [
                { type: 'clients', id },
                { type: 'clients', id: 'LIST' },
            ],
        }),
        updateEquipment: builder.mutation({
            query: ({ id, updateData }) => ({
                url: `/clients/${id}/equipment`,
                method: 'PATCH',
                body: {
                    ...updateData
                },
            }),
            invalidatesTags: (result, error, { id }) => [
                { type: 'clients', id },
                { type: 'clients', id: 'LIST' },
            ],
        }),
        updateContactHistory: builder.mutation({
            query: ({ clientId, updateData }) => ({
                url: `/clients/${clientId}/contact_history`,
                method: 'PATCH',
                body: {
                    ...updateData
                },
            }),
            invalidatesTags: (result, error, { id }) => [
                { type: 'clients', id },
                { type: 'clients', id: 'LIST' },
            ],
        }),
        addShop: builder.mutation({
            query: ({ clientId, updateData }) => ({
                url: `/clients/${clientId}/add_shop`,
                method: 'PATCH',
                body: {
                    ...updateData
                },
            }),
            invalidatesTags: (result, error, { id }) => [
                { type: 'clients', id },
                { type: 'clients', id: 'LIST' },
            ],
        })
    })
})

export const {
    useGetClientsQuery,
    useCreateClientsMutation,
    useGetSingleClientQuery,
    useUpdateClientMutation,
    useUpdateRecipesMutation,
    useUpdateEquipmentMutation,
    useUpdateContactHistoryMutation,
    useAddShopMutation
} = clientsApiSlice

export const selectClientsResult = clientsApiSlice.endpoints.getClients.select()

const selectClientData = createSelector(
    selectClientsResult,
    clientResult => clientResult.data
)