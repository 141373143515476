import { useGetCoffeeStoresQuery } from '../features/coffeeStores/storesApiSlice';

const useStoreNames = () => {
    // Get Coffee Stores Data From Query
    const {
        data: coffeeStores,
        isLoading,
        isSuccess,
        isError,
        error,
    } = useGetCoffeeStoresQuery({
        pollingInterval: 15000,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true,
    });

    if (isLoading) return { isLoading, storeList: [] };
    if (isError) return { isError, error, storeList: [] };
    if (isSuccess) return { storeList: coffeeStores?.allStores || [] };

    // Handle any fallback scenario
    return { storeList: [], isLoading, isSuccess, isError, error };
};

export default useStoreNames;
