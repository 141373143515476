import React, { useEffect, useState } from 'react'
import { Container, Form, Button, Col, Row } from 'react-bootstrap';
import { useCreateClientsMutation } from './clientsApiSlice';
import useAuth from '../../hooks/useAuth';
import { useLocation, useNavigate } from 'react-router-dom';

const CreateClient = () => {

    const [createClients, {
        isLoading,
        isSuccess,
        isError,
        error
    }] = useCreateClientsMutation()

    const navigate = useNavigate()

    const { id: userId } = useAuth()

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        contactName: '',
        category: '',
        checklistFrequency: '',
        seminarFrequency: '',
        shops: [{
            shop_name: '',
            city: '',
            prefecture: '',
            recipes: [{
                coffee: '',
                doseIn: '',
                doseOut: '',
                timeSec: ''
            }],
            equipment: [{
                type: '',
                brand: '',
                model: '',
                serial_number: ''
            }]
        }]
    })


    const equipmentTypes = ['Grinder', 'Espresso Machine']

    const handleBasicInputChange = (e) => {
        const { name, value } = e.target
        const newFormData = { ...formData }
        newFormData[name] = value

        setFormData(newFormData)
    }

    const handleInputChange = (e, shopIndex, equipmentIndex, field, type = 'shop') => {
        const { name, value } = e.target;
        const newFormData = { ...formData };

        if (type === 'shop') {
            newFormData.shops[shopIndex][field] = value;
        } else if (type === 'equipment') {
            newFormData.shops[shopIndex].equipment[equipmentIndex][field] = value;
        } else if (type === 'undefined') {
            newFormData[name] = value;
        }

        setFormData(newFormData);
    };

    // Handle recipe-specific changes
    const handleRecipeChange = (e, shopIndex, recipeIndex, field) => {
        const { value } = e.target;
        const updatedShops = [...formData.shops];

        // Update the recipe field for the given shop and recipe
        updatedShops[shopIndex].recipes[recipeIndex][field] = value;

        setFormData({ ...formData, shops: updatedShops });
    };

    const addShop = () => {
        const newShop = {
            shop_name: '',
            city: '',
            category: '',
            prefecture: '',
            recipes: [{
                coffee: '',
                doseIn: '',
                doseOut: '',
                timeSec: ''
            }],
            equipment: [
                {
                    type: '',
                    brand: '',
                    model: '',
                    serial_number: ''
                }
            ]
        };
        setFormData({ ...formData, shops: [...formData.shops, newShop] });
    };

    const addEquipment = (shopIndex) => {
        const newEquipment = {
            type: '',
            brand: '',
            model: '',
            serial_number: ''
        };
        const newFormData = { ...formData };
        newFormData.shops[shopIndex].equipment.push(newEquipment);
        setFormData(newFormData);
    };

    const addRecipe = (shopIndex) => {
        const updatedShops = [...formData.shops];
        updatedShops[shopIndex].recipes.push({ coffee: '' }); // Add an empty recipe object
        setFormData({ ...formData, shops: updatedShops });
    };

    const removeShop = (shopIndex) => {
        const newFormData = { ...formData };
        newFormData.shops.splice(shopIndex, 1);
        setFormData(newFormData);
    };

    const removeEquipment = (shopIndex, equipmentIndex) => {
        const newFormData = { ...formData };
        newFormData.shops[shopIndex].equipment.splice(equipmentIndex, 1);
        setFormData(newFormData);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        // console.log('Submitting...')
        // console.log('Form:', formData);

        try {
            const payload = { ...formData, userId };
            // console.log('Payload:', payload);

            if (!userId) {
                throw new Error('User ID is required');
            }
            await createClients(payload).unwrap()
            // console.log('success')
        } catch (err) {
            const errorMessage = error.data?.message || 'An unexpected error occurred';
        }
    };

    useEffect(() => {
        if (isSuccess) {
            navigate("/dashboard/clients", { state: { successMsg: true } })
        }
    }, [isSuccess, navigate])

    return (
        <>
            <Container className='overflow-hidden'>
                <Form onSubmit={handleSubmit}>
                    <Form.Group className="mb-3">
                        <Form.Label>Επωνυμία</Form.Label>
                        <Form.Control
                            type="text"
                            name="name"
                            value={formData.name}
                            onChange={(e) => handleBasicInputChange(e)}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={(e) => handleBasicInputChange(e)}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Υπεύθυνος Επικοινωνίας</Form.Label>
                        <Form.Control
                            type="text"
                            name="contactName"
                            value={formData.contactName}
                            onChange={(e) => handleBasicInputChange(e)}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Κατηγορία</Form.Label>
                        <Form.Control
                            type="text"
                            name="category"
                            value={formData.category}
                            onChange={(e) => handleBasicInputChange(e)}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Συχνότητα Checklist (Σε ημέρες)</Form.Label>
                        <Form.Control
                            type="text"
                            name="checklistFrequency"
                            value={formData.checklistFrequency || ""}
                            onChange={(e) => handleBasicInputChange(e)}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Συχνότητα Σεμιναρίων (Σε ημέρες)</Form.Label>
                        <Form.Control
                            type="text"
                            name="seminarFrequency"
                            value={formData.seminarFrequency || ""}
                            onChange={(e) => handleBasicInputChange(e)}
                        />
                    </Form.Group>

                    {formData.shops.map((shop, shopIndex) => (
                        <div key={shopIndex} className="mb-4">
                            <h5>Κατάστημα {shopIndex + 1}</h5>
                            <Form.Group className="mb-3">
                                <Form.Label>Όνομα Καταστήματος</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={shop.shop_name}
                                    onChange={(e) => handleInputChange(e, shopIndex, null, 'shop_name')}
                                />
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>Πόλη</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={shop.city}
                                    onChange={(e) => handleInputChange(e, shopIndex, null, 'city')}
                                />
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>Νομός</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={shop.prefecture}
                                    onChange={(e) => handleInputChange(e, shopIndex, null, 'prefecture')}
                                />
                            </Form.Group>

                            <h6>Συνταγές</h6>
                            {shop.recipes.map((recipe, recipeIndex) => (
                                <div key={recipeIndex}>
                                    <Row>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Καφές</Form.Label>
                                            <Form.Control
                                                type="text"
                                                value={recipe.coffee}
                                                onChange={(e) => handleRecipeChange(e, shopIndex, recipeIndex, 'coffee')}
                                            />
                                        </Form.Group>
                                        <Col>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Dose In</Form.Label>
                                                <Form.Control
                                                    type="number"
                                                    value={shop.recipes.doseIn}
                                                    onChange={(e) => handleRecipeChange(e, shopIndex, recipeIndex, 'doseIn')}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Dose Out</Form.Label>
                                                <Form.Control
                                                    type="number"
                                                    value={shop.recipes.doseOut}
                                                    onChange={(e) => handleRecipeChange(e, shopIndex, recipeIndex, 'doseOut')}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Time (Sec)</Form.Label>
                                                <Form.Control
                                                    type="number"
                                                    value={shop.recipes.timeSec}
                                                    onChange={(e) => handleRecipeChange(e, shopIndex, recipeIndex, 'timeSec')}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </div>
                            ))}

                            <Button
                                onClick={() => addRecipe(shopIndex)}
                                className="primary-btn"
                            >
                                Προσθήκη Συνταγής
                            </Button>

                            <h6>Equipment</h6>
                            {shop.equipment.map((equip, equipmentIndex) => (
                                <div key={equipmentIndex} className="mb-3">
                                    <Row>
                                        <Col>
                                            <Form.Group>
                                                <Form.Label>Type</Form.Label>
                                                <Form.Select
                                                    value={equip.type}
                                                    onChange={(e) => handleInputChange(e, shopIndex, equipmentIndex, 'type', 'equipment')}
                                                >
                                                    <option value="">Select Type</option>
                                                    {equipmentTypes.map((type) => (
                                                        <option key={type} value={type}>
                                                            {type}
                                                        </option>
                                                    ))}
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group>
                                                <Form.Label>Brand</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    value={equip.brand}
                                                    onChange={(e) => handleInputChange(e, shopIndex, equipmentIndex, 'brand', 'equipment')}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group>
                                                <Form.Label>Model</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    value={equip.model}
                                                    onChange={(e) => handleInputChange(e, shopIndex, equipmentIndex, 'model', 'equipment')}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group>
                                                <Form.Label>Serial Number</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    value={equip.serial_number}
                                                    onChange={(e) => handleInputChange(e, shopIndex, equipmentIndex, 'serial_number', 'equipment')}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col className="d-flex align-items-end">
                                            <Button
                                                className='cancel-btn'
                                                onClick={() => removeEquipment(shopIndex, equipmentIndex)}
                                            >
                                                Διαγραφή εξοπλισμού
                                            </Button>
                                        </Col>
                                    </Row>
                                </div>
                            ))}

                            <Button
                                onClick={() => addEquipment(shopIndex)}
                                className='primary-btn'
                            >
                                Προσθήκη Εξοπλισμού
                            </Button>

                            <hr />

                            <Button
                                onClick={() => removeShop(shopIndex)}
                                className='cancel-btn'
                            >
                                Διαγραφή Καταστήματος
                            </Button>
                        </div>
                    ))}

                    <Button onClick={addShop} className="mb-3 primary-btn">
                        Προσθήκη Καταστήματος
                    </Button>

                    <hr></hr>

                    <Button type="submit" className='primary-btn'>
                        Αποθήκευτση
                    </Button>
                </Form>
            </Container>
        </>
    );
};

export default CreateClient