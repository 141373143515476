import React, { useEffect, useState } from 'react'
import useAuth from '../../hooks/useAuth';
import { Alert, Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { useUpdateContactHistoryMutation } from './clientsApiSlice';

const VisitModal = ({ clientId, storeName }) => {

    const [show, setShow] = useState(false);
    const [showSuccessMsg, setShowSuccessMsg] = useState(false)
    const { id } = useAuth()

    const [updateContactHistory, { isLoading, isSuccess, error }] = useUpdateContactHistoryMutation()

    const [formData, setFormData] = useState({
        type: "",
        date: "",
        user: id,
        comments: ""
    });

    // Handle opening and closing of the modal
    const handleShow = () => setShow(true);
    const handleClose = () => {
        setShow(false);
        setShowSuccessMsg(false)
    }

    // Handle form input changes
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    // Handle form submission
    const handleVisitSubmit = async (e) => {
        e.preventDefault();
        e.stopPropagation(); // Prevent the event from bubbling up to the parent form
        // Handle form submission logic here
        try {
            // console.log('submitting... ')
            const updateData = { ...formData };
            if (clientId){
                await updateContactHistory({ clientId, updateData }).unwrap();
            } else if (storeName) {
                await updateContactHistory({ storeName, updateData }).unwrap();
            }
            setShowSuccessMsg(true)
            // console.log("Client updated successfully!");
        } catch (error) {
            console.error("Failed to update client:", error);
        }
    };

    // UseEffect to reset form after successful submission
    useEffect(() => {
        if (isSuccess) {
            setFormData({ visitDetails: '' }); // Reset form fields to empty
        }
    }, [isSuccess]); // Dependency on isSuccess and isSubmitted

    // Reset form
    const handleReset = () => {
        setFormData({ visitDetails: '' });
        setShowSuccessMsg(false)
    };

    return (
        <>
            {/* Button that triggers the modal */}
            <Button className="primary-btn" onClick={handleShow}>
                Προσθήκη επικοινωνίας
            </Button>

            {/* Modal */}
            <Modal show={show} onHide={handleClose} size="xl">
                <Modal.Header closeButton>
                    <Modal.Title>Προσθήκη επικοινωνίας</Modal.Title>
                </Modal.Header>
                {/* If the request is successful, show success message */}
                {isSuccess && showSuccessMsg ? (
                    <>
                        {/* If form is submitted, show the success message*/}
                        <Modal.Body>
                            <Alert variant="success">
                                Τα στοιχεία επικοινωνίας καταχωρήθηκαν επιτυχώς!

                            </Alert>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button className='primary-btn' onClick={handleReset}>Προσθήκη Επικοινωνίας</Button>
                            <Button className='cancel-btn' onClick={handleClose}>Κλείσιμο</Button>
                        </Modal.Footer>
                    </>
                ) : (
                    <Form onSubmit={handleVisitSubmit}>
                        <Modal.Body>
                            <Row className="mb-3">
                                {/* Type Field */}
                                <Form.Group as={Col} xs={12} md={6} controlId="type">
                                    <Form.Label>Type</Form.Label>
                                    <Form.Select
                                        name="type"
                                        value={formData.type || ""}
                                        onChange={handleInputChange}
                                        required
                                    >
                                        <option value="">Επιλογή Τύπου</option>
                                        <option value="ΕΠΙΣΚΕΨΗ">Επίσκεψη</option>
                                        <option value="ΤΗΛΕΦΩΝΙΚΗ">Τηλεφωνική</option>
                                    </Form.Select>
                                </Form.Group>

                                {/* Date Field */}
                                <Form.Group as={Col} xs={12} md={6} controlId="date">
                                    <Form.Label>Date</Form.Label>
                                    <Form.Control
                                        type="date"
                                        name="date"
                                        value={formData.date || ""}
                                        onChange={handleInputChange}
                                        required
                                    />
                                </Form.Group>
                            </Row>

                            <Row className="mb-3">


                                {/* Comments Field */}
                                <Form.Group as={Col} xs={12} md={12} controlId="comments">
                                    <Form.Label>Comments</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        name="comments"
                                        value={formData.comments || ""}
                                        onChange={handleInputChange}
                                        rows={3}
                                    />
                                </Form.Group>
                            </Row>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                                variant="secondary"
                                className="cancel-btn"
                                onClick={handleClose}
                            >
                                Κλείσιμο
                            </Button>
                            {/* Submit button for the form */}
                            <Button type="submit" className="primary-btn">
                                Αποθήκευση
                            </Button>
                        </Modal.Footer>
                    </Form>
                )}
            </Modal >
        </>
    );
}

export default VisitModal