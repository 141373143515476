import React, { useEffect, useState } from 'react'
import { useGetSchedulesByCategoryQuery, useRemoveEntryMutation, useUpdateEntryStatusMutation } from '../Schedules/userScheduleApiSlice'
import { useLocation } from 'react-router-dom';
import Schedule from '../../components/Schedule';

const SalesSchedules = () => {
    // Filter state
    const [formData, setFormData] = useState({
        categories: ['ΕΠΙΣΚΕΨΗ', 'ΧΑΡΤΟΓΡΑΦΗΣΗ', 'ΔΕΙΓΜΑΤΙΣΜΟΣ', 'ΑΛΛΑΓΗ ΕΞΟΠΛΙΣΜΟΥ', 'ΕΓΚΑΤΑΣΤΑΣΗ ΕΞΟΠΛΙΣΜΟΥ'],
        sorting: "asc",
        date: "",
        userId: "",
    });

    const { data: salesSchedule, isSuccess, refetch } = useGetSchedulesByCategoryQuery(formData);
    const [updateEntryStatus, { isLoading: statusUpdateLoading }] = useUpdateEntryStatusMutation();
    const [removeEntry, { isSuccess: removeSuccess }] = useRemoveEntryMutation();
    const location = useLocation();
    const [successMsg, setSuccessMsg] = useState(location.state?.successMsg || false);
    const [showSuccessMsg, setShowSuccessMsg] = useState(false);

    useEffect(() => {
        if (successMsg) {
            const timer = setTimeout(() => {
                setSuccessMsg(false); // Remove message after 5 seconds
            }, 5000);
            return () => clearTimeout(timer); // Cleanup timer when component unmounts
        }
    }, [successMsg]);

    const handleUpdateStatus = async (entryId) => {
        try {
            await updateEntryStatus(entryId);
        } catch (error) {
            console.error("Error updating status:", error);
        }
        refetch()
    }

    const handleDelete = async (id) => {
        const isConfirmed = window.confirm("Είστε σίγουρος ότι θέλετε να διαγράψετε αυτή την καταχώρηση");
        if (isConfirmed) {
            try {
                await removeEntry({ id })
                setShowSuccessMsg(true)
            } catch (err) {
                console.error(err)
            }
            refetch()
        }
    };

    return <Schedule
        addButtonText='Νέο Πρόγραμμα Πωλήσεων'
        title='ΠΡΟΓΡΑΜΜΑ ΠΩΛΗΣΕΩΝ'
        categories={['ΕΠΙΣΚΕΨΗ', 'ΧΑΡΤΟΓΡΑΦΗΣΗ', 'ΔΕΙΓΜΑΤΙΣΜΟΣ', 'ΑΛΛΑΓΗ ΕΞΟΠΛΙΣΜΟΥ', 'ΕΓΚΑΤΑΣΤΑΣΗ ΕΞΟΠΛΙΣΜΟΥ']}
        formData={formData} // Pass filter state
        setFormData={setFormData} // Allow Schedule to update filters
        scheduleData={salesSchedule}
        isSuccess={isSuccess}
        handleUpdateStatus={handleUpdateStatus}
        handleDelete={handleDelete}
        removeSuccess={removeSuccess}
        showSuccessMsg={showSuccessMsg}
        successMsg={successMsg}
        statusUpdateLoading={statusUpdateLoading}
    />
}

export default SalesSchedules