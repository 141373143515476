import { createSelector, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../../app/api/apiSlice";

const coffeeStoresAdapter = createEntityAdapter({})
const initialState = coffeeStoresAdapter.getInitialState()

export const coffeeStoresApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getCoffeeStores: builder.query({
            query: ({ page = 1, limit = 20, search = '' }) => {
                const searchParams = new URLSearchParams({ page, limit });
                Object.keys(search).forEach(key => searchParams.append(`search[${key}]`, search[key]));
                return `coffeestores?${searchParams.toString()}`
            },
            validateStatus: (response, result) => {
                return response.status === 200 && !result.isError
            },
            transformResponse: responseData => {
                const loadedCoffeeStores = responseData.stores.map(coffeeStore => {
                    return { ...coffeeStore, id: coffeeStore._id }
                });
                return {
                    stores: coffeeStoresAdapter.setAll(initialState, loadedCoffeeStores),
                    currentPage: responseData.currentPage,
                    totalPages: responseData.totalPages,
                    totalItems: responseData.totalItems,
                    allPrefectures: responseData.allPrefectures,
                    allCities: responseData.allCities,
                    allCoffee: responseData.allCoffee,
                    allStores: responseData.allStores
                }
            },
            providesTags: (result, error, arg) => {
                if (result?.ids) {
                    return [
                        { type: 'Stores', id: 'PARTIAL-LIST' },
                        ...result.ids.map(id => ({ type: 'Stores', id }))
                    ]
                } else return [{ type: 'Stores', id: 'PARTIAL-LIST' }]
            }
        }),
        addNewCoffeeStore: builder.mutation({
            query: initialCoffeeStoreData => ({
                url: '/coffeestores',
                method: 'POST',
                body: {
                    ...initialCoffeeStoreData,
                }
            }),
            invalidatesTags: [
                { type: 'Stores', id: 'LIST' }
            ]
        }),
        updateStore: builder.mutation({
            query: initialStore => ({
                url: '/coffeestores',
                method: 'PATCH',
                body: {
                    ...initialStore,
                }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Stores', id: arg.id }
            ]
        }),
        addVisit: builder.mutation({
            query: initialVisitData => ({
                url: '/coffeestores/addVisit',
                method: 'POST',
                body: {
                    ...initialVisitData
                }
            }),
            invalidateTags: [
                { type: 'Stores', id: 'LIST' }
            ]
        }),
        addSampling: builder.mutation({
            query: initialSamplingData => ({
                url: '/coffeestores/addSampling',
                method: 'POST',
                body: {
                    ...initialSamplingData
                }
            }),
            invalidateTags: [
                { type: 'Stores', id: 'LIST' }
            ]
        }),
        getStoreVisits: builder.query({
            query: () => '/coffeestores/getVisits',
            validateStatus: (response, result) => {
                return response.status === 200 && !result.isError
            },
            transformResponse: responseData => {
                const loadedVisits = responseData.map(visit => ({
                    ...visit,
                    id: visit._id
                }))
                return coffeeStoresAdapter.setAll(initialState, loadedVisits)
            },
            providesTags: (result, error, arg) => {
                if (result?.ids) {
                    return [
                        { type: 'Visits', id: 'LIST' },
                        ...result.ids.map(id => ({ type: 'Visit', id }))
                    ]
                } else return ({ type: 'Visits', id: 'LIST' })
            }
        }),
        getStoreSamplings: builder.query({
            query: () => '/coffeestores/getSamplings',
            validateStatus: (response, result) => {
                return response.status === 200 && !result.isError
            },
            transformResponse: responseData => {
                const loadedSamplings = responseData.map(sampling => ({
                    ...sampling,
                    id: sampling._id
                }))
                return coffeeStoresAdapter.setAll(initialState, loadedSamplings)
            },
            providesTags: (result, error, arg) => {
                if (result?.ids) {
                    return [
                        { type: 'Samplings', id: 'LIST' },
                        ...result.ids.map(id => ({ type: 'Sampling', id }))
                    ]
                } else return ({ type: 'Samplings', id: 'LIST' })
            }
        })
    })
})

export const {
    useGetCoffeeStoresQuery,
    useAddNewCoffeeStoreMutation,
    useUpdateStoreMutation,
    useAddVisitMutation,
    useAddSamplingMutation,
    useGetStoreVisitsQuery,
    useGetStoreSamplingsQuery
} = coffeeStoresApiSlice

// returs the query resul oject
export const selectCoffeeStoresResult = coffeeStoresApiSlice.endpoints.getCoffeeStores.select();

// creates memoized selector
const selectCoffeeStoresData = createSelector(
    selectCoffeeStoresResult,
    coffeeStoresResult => coffeeStoresResult.data // normalized state object with ids & entities
)

// getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllCoffeeStores,
    selectById: SelectCoffeeStoreById,
    selectIds: selectCoffeeStoreIds
    // Pass in a selector that returs the users slice of state
} = coffeeStoresAdapter.getSelectors(state => selectCoffeeStoresData(state) ?? initialState)