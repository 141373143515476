import React, { useEffect, useState } from 'react';
import { Alert, Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { faMinus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useUpdateRecipesMutation } from './clientsApiSlice';

const RecipeModal = ({ recipes, id, shopId }) => {
    const [show, setShow] = useState(false);
    const [isEditable, setIsEditable] = useState(false);
    const [showSuccessMsg, setShowSuccessMsg] = useState(false)

    const [updateRecipes, {
        isSuccess
    }] = useUpdateRecipesMutation()

    const [formData, setFormData] = useState(
        recipes?.map((item) => ({
            coffee: item.coffee || "",
            doseIn: item.doseIn || "",
            doseOut: item.doseOut || "",
            timeSec: item.timeSec || ""
        }))
    )

    // Handle opening and closing of the modal
    const handleShow = () => setShow(true);
    const handleClose = () => {
        setShow(false);
        setShowSuccessMsg(false)
        setIsEditable(!isEditable);
    }

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        e.stopPropagation(); // Prevent the event from bubbling up to the parent form
        // Handle form submission logic here
        try {
            // console.log('submitting... ', shopId)
            const updateData = { ...formData, shopId };
            await updateRecipes({ id, updateData }).unwrap();
            // console.log("Client updated successfully!");
        } catch (error) {
            console.error("Failed to update client:", error);
        }
    };

    // Handle input change
    const handleInputChange = (index, e) => {
        const { name, value } = e.target
        const updatedFormData = [...formData];
        updatedFormData[index][name] = value;
        setFormData(updatedFormData);
    };

    // Handle toggle between edit and save
    const handleEditToggle = () => {
        setIsEditable(!isEditable);
    };

    // Handle remove specific equipment
    const handleRemoveRecipe = (index) => {
        const updatedFormData = [...formData];
        updatedFormData.splice(index, 1); // Remove the item at the specified index
        setFormData(updatedFormData);
    };

    const handleAddRecipe = () => {
        const newRecipe = {
            coffee: '',
            doseIn: '',
            doseOut: '',
            timeSec: ''
        };
        setFormData([...formData, newRecipe]);
    };

    // Reset form
    const handleReset = () => {
        setShowSuccessMsg(false)
    };

    return (
        <div>
            {/* Button that triggers the modal */}
            <Button className="primary-btn" onClick={handleShow}>Προβολή Συνταγής</Button>

            {/* Modal */}
            <Modal show={show} onHide={handleClose} size='xl'>
                <Modal.Header closeButton>
                    <Modal.Title>Συνταγή</Modal.Title>
                </Modal.Header>
                {/* If the request is successful, show success message */}
                {isSuccess && showSuccessMsg ? (
                    <>
                        {/* If form is submitted, show the success message*/}
                        <Modal.Body>
                            <Alert variant="success">
                                Οι συνταγές ενημερώθηκαν επιτυχώς!

                            </Alert>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button className='primary-btn' onClick={handleReset}>Επεξεργασία Συνταγών</Button>
                            <Button className='cancel-btn' onClick={handleClose}>Κλείσιμο</Button>
                        </Modal.Footer>
                    </>
                ) : (
                    <Form onSubmit={handleSubmit}>
                        <Modal.Body>
                            {/* Display equipment content and make it editable if needed */}
                            {Array.isArray(formData) && formData?.map((item, index) => (
                                <Row key={index} className="mb-3">
                                    <Form.Group as={Col} xs={12} md={3} controlId={`coffee-${index}`}>
                                        <Form.Label>Καφές</Form.Label>
                                        <Form.Control type="text" name='coffee' value={item.coffee} onChange={(e) => handleInputChange(index, e)} disabled={!isEditable} required />
                                    </Form.Group>
                                    <Form.Group as={Col} xs={12} md={3} controlId={`doseIn-${index}`}>
                                        <Form.Label>Dose In</Form.Label>
                                        <Form.Control type="text" name='doseIn' value={item.doseIn} onChange={(e) => handleInputChange(index, e)} disabled={!isEditable} required />
                                    </Form.Group>
                                    <Form.Group as={Col} xs={12} md={3} controlId={`doseOut-${index}`}>
                                        <Form.Label>Dose Out</Form.Label>
                                        <Form.Control type="text" name='doseOut' value={item.doseOut} onChange={(e) => handleInputChange(index, e)} disabled={!isEditable} required />
                                    </Form.Group>
                                    <Form.Group as={Col} xs={12} md={2} controlId={`timeSec-${index}`}>
                                        <Form.Label>Χρόνος</Form.Label>
                                        <Form.Control type="number" name='timeSec' value={item.timeSec} onChange={(e) => handleInputChange(index, e)} disabled={!isEditable} required />
                                    </Form.Group>
                                    <Col xs={12} md={1} className="text-end">
                                        <Button
                                            variant="danger"
                                            onClick={() => handleRemoveRecipe(index)}
                                            disabled={!isEditable}
                                            style={{ marginTop: "30px" }}
                                        >
                                            <FontAwesomeIcon icon={faMinus} />
                                        </Button>
                                    </Col>
                                </Row>
                            ))}
                            {/* Add Equipment Button */}
                            {isEditable && (
                                <div className="text-center mt-3">
                                    <Button variant="success" className='add-btn' onClick={handleAddRecipe}>
                                        Προσθήκη Συνταγής
                                    </Button>
                                </div>
                            )}
                        </Modal.Body>
                        <Modal.Footer>
                            {/* Toggle buttons */}
                            {!isEditable ? (
                                <Button variant="primary" className='primary-btn' onClick={handleEditToggle}>
                                    Επεξεργασία
                                </Button>
                            ) : (
                                <>
                                    <Button variant="secondary" className='cancel-btn' onClick={handleClose}>
                                        Κλείσιμο
                                    </Button>
                                    {/* Submit button for the form */}
                                    <Button type="submit" className='primary-btn'>
                                        Αποθήκευση
                                    </Button>
                                </>
                            )}
                        </Modal.Footer>
                    </Form>
                )}
            </Modal>
        </div>
    );
};

export default RecipeModal;
