import React, { useState, useEffect, useRef } from 'react'
import { useGetCoffeeStoresQuery } from './storesApiSlice'
import { useLocation, useNavigate } from 'react-router-dom'
import { storeTypesList } from '../../config/storeTypes'
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import { Button, Col, Dropdown, DropdownToggle, Row } from 'react-bootstrap'
import { faBars, faEye, faFilter, faPlus, faPrint } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import '../../assets/css/table.css'
import '../../assets/css/card.css'
import StoreCard from './StoreCard'
import NewStoreForm from './NewStoreForm'
import VisitForm from './forms/VisitForm'
import VisitList from './VisitList'
import SamplingForm from './forms/SamplingForm'
import SamplingsList from './SamplingsList'
import AddToSchedule from '../Schedules/AddToSchedule';


const CoffeeStoresList = () => {

  const location = useLocation();
  const [visibleMessage, setVisibleMessage] = useState(location.state?.message);
  const [currentPage, setCurrentPage] = useState(1)
  const [searchQuery, setSearchQuery] = useState()

  const [searchFields, setSearchFields] = useState({ prefecture: '', city: '', storeType: '', entryType: '', date: '', hasVisit: '', hasSampling: '', coffee: '' })

  // Get Coffee Stores Data From Query
  const {
    data: coffeeStores,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetCoffeeStoresQuery({ page: currentPage, limit: 25, search: searchQuery }, {
    pollingInterval: 15000,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true
  })

  // Handle Pagination
  const nextPage = () => {
    if (coffeeStores && currentPage < coffeeStores.totalPages) {
      setCurrentPage(prevPage => prevPage + 1);
    }
  };

  const prevPage = () => {
    if (coffeeStores && currentPage > 1) {
      setCurrentPage(prevPage => prevPage - 1);
    }
  };

  let content

  if (isLoading) content = <p>Loading...</p>

  if (isError) {
    content = <p>{error?.data?.message}</p>
  }

  useEffect(() => {
    // If there's a message, set a timer to clear it after 10 seconds
    if (visibleMessage) {
      const timer = setTimeout(() => {
        setVisibleMessage(null); // Clears the message
      }, 10000); // 10 seconds

      // Clean up the timer if the component unmounts
      return () => clearTimeout(timer);
    }
  }, [visibleMessage]);


  const [selectedId, setSelectedId] = useState(null);
  const [showViewModal, setShowViewModal] = useState(false)

  const [selectedStore, setSelectedStore] = useState(null)

  const handleViewClick = (storeId, thisStore) => {
    setSelectedStore(thisStore)
    setSelectedId(storeId)
    setShowViewModal(true)
  }

  const handleViewModalClose = () => {
    setSelectedStore(null)
    setSelectedId(null)
    setShowViewModal(false)
  }

  const [showScheduleModal, setScheduleModal] = useState(false)
  const [selectedScheduleStore, setSelectedScheduleStore] = useState(null)
  const [scheduleEntryType, setScheduleEntryType] = useState('')

  const handleShowScheduleModal = (selectedStore, entryType) => {
    setSelectedScheduleStore(selectedStore)
    setScheduleEntryType(entryType)
    setScheduleModal(true)
  }

  const handleCloseScheduleModal = () => {
    setSelectedScheduleStore(null)
    setScheduleModal(false)
  }

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setSearchFields({
      ...searchFields,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleSearch = () => {
    setSearchQuery(searchFields)
    setCurrentPage(1)
  }

  const clearSearch = () => {
    setSearchFields({ prefecture: '', city: '', storeType: '', entryType: '', date: '', hasVisit: '', hasSampling: '', coffee: '' })
    setSearchQuery({})
    setCurrentPage(1)
  }


  if (isSuccess) {
    const { ids, entities } = coffeeStores.stores
    // Get Page Data from query 
    const { currentPage, totalPages, allPrefectures, allCities, allCoffee } = coffeeStores;

    content = (
      <>
        <Container fluid className='overflow-hidden'>
          <div className='page-title-box d-sm-flex align-items-center justify-content-between'>
            <h4>ΧΑΡΤΟΓΡΑΦΗΣΗ ΚΑΤΑΣΤΗΜΑΤΩΝ</h4>
          </div>
          {/* Start Of content helper buttons */}
          <Row>
            <Col lg={12}>
              <div className='card'>
                <div className='card-header'>
                  <div className='d-flex align-items-center flex-wrap gap-2 mt-2 mb-2'>
                    <div className='flex-grow-1'>
                      <NewStoreForm />
                    </div>
                    <div className='flex-shrink-0'>
                      <button className='secondary-btn isDisabled' type="button">
                        <FontAwesomeIcon icon={faPrint} /> Εκτύπωση
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          {/* Start Of filter */}
          <Row>
            <Col lg={12}>
              <div className='card'>
                <div className='card-header'>
                  <div className='d-flex align-items-center flex-wrap gap-2 mt-2 mb-2'>
                    <Form.Group as={Col} lg={1} xs={12} className='mb-2'>
                      <Form.Control type='date' name='date' value={searchFields['date']} onChange={handleChange} />
                    </Form.Group>
                    <Form.Group as={Col} lg={1} xs={12} className='mb-2'>
                      <Form.Control as="select" name="prefecture" value={searchFields["prefecture"]} onChange={handleChange}>
                        <option>Επιλογή Νομού</option>
                        {allPrefectures.map(pref => (
                          <option key={pref} value={pref}>
                            {pref}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                    <Form.Group as={Col} lg={1} xs={12} className='mb-2'>
                      <Form.Control as="select" name="city" value={searchFields["city"]} onChange={handleChange}>
                        <option>Επιλογή Πόλης</option>
                        {allCities.map(city => (
                          <option key={city} value={city}>
                            {city}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                    <Form.Group as={Col} lg={1} xs={12} className='mb-2'>
                      <Form.Control as="select" name="entryType" value={searchFields['entryType']} onChange={handleChange}>
                        <option key='---' value=''>Επιλογή Καταχώρησης</option>
                        <option key='ΑΠΕΥΘΕΙΑΣ' value='ΑΠΕΥΘΕΙΑΣ'>Απευθείας</option>
                        <option key='ΧΑΡΤΟΓΡΑΦΗΣΗ' value='ΧΑΡΤΟΓΡΑΦΗΣΗ'>Χαρτογράφηση</option>
                      </Form.Control>
                    </Form.Group>
                    <Form.Group as={Col} lg={1} xs={12} className='mb-2'>
                      <Form.Control as="select" name="storeType" value={searchFields['storeType']} onChange={handleChange}>
                        <option key='---' value=''>Επιλογή Τύπου Καταστήματος</option>
                        {storeTypesList}
                      </Form.Control>
                    </Form.Group>
                    <Form.Group as={Col} lg={1} xs={12} className='mb-2'>
                      <Form.Control as="select" name="coffee" value={searchFields['coffee']} onChange={handleChange}>
                        <option value={""}>Επιλογή Καφέ</option>
                        {allCoffee.map(coffee => (
                          <option key={coffee} value={coffee}>
                            {coffee}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                    <Form.Group as={Col} lg={1} xs={12} className='mb-2'>
                      <Form.Check type="checkbox" id="hasVisit" name='hasVisit' label="Έχει Επισκέψεις" checked={searchFields['hasVisit']} onChange={handleChange} />
                    </Form.Group>
                    <Form.Group as={Col} lg={1} xs={12} className='mb-2'>
                      <Form.Check type="checkbox" id="hasSampling" name="hasSampling" label="Έχει Δειγματισμούς" checked={searchFields['hasSampling']} onChange={handleChange} />
                    </Form.Group>
                    <Form.Group as={Col} lg={1} xs={12} className='mb-2'>
                      <Button className="primary-btn w-100" onClick={handleSearch}>
                        Αναζήτηση
                      </Button>
                    </Form.Group>
                    <Form.Group as={Col} lg={1} xs={12} className='mb-2'>
                      <Button className="secondary-btn w-100" onClick={clearSearch}>
                        Καθαρισμός
                      </Button>
                    </Form.Group>
                  </div>
                </div>
              </div>
            </Col>
          </Row>

          {/* End Of filter */}

          {visibleMessage && <div className='successMessage'>{visibleMessage}</div>}

          {/*}

          {/* Start Of content table */}
          <Row className="mt-2">
            <Col sm={12} className="mt-2">
              <div className='content-wrapper'>
                <div className='content-data pt-4 table-responsive'>
                  <Table responsive hover className='table'>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Ημερομηνία Εγγραφής</th>
                        <th>Νομός</th>
                        <th>Πόλη</th>
                        <th>Όνομα Καταστήματος</th>
                        <th>Καταχώρηση</th>
                        <th>Τύπος Καταστήματος</th>
                        <th>Καφές</th>
                        <th width="5%">Κατανάλωση (Εκτίμηση)</th>
                        <th width="10%">Επικοινωνία</th>
                        <th width="10%">Δειγματισμός</th>
                        <th>Ενέργειες</th>
                      </tr>
                    </thead>
                    <tbody>
                      {ids.map((id, index) => (
                        <tr key={id} style={{ cursor: 'pointer' }}>
                          <td onClick={() => handleViewClick(id, entities[id])}>{(currentPage - 1) * 20 + index + 1}</td>
                          <td onClick={() => handleViewClick(id, entities[id])}>{new Date(entities[id].createdAt).toLocaleString('el-GR', { day: 'numeric', month: 'short', year: 'numeric' })}</td>
                          <td onClick={() => handleViewClick(id, entities[id])}>{entities[id].prefecture}</td>
                          <td onClick={() => handleViewClick(id, entities[id])}>{entities[id].city}</td>
                          <td onClick={() => handleViewClick(id, entities[id])}>{entities[id].storeTitle}</td>
                          <td onClick={() => handleViewClick(id, entities[id])}>{entities[id].entryType}</td>
                          <td onClick={() => handleViewClick(id, entities[id])}>{entities[id].storeType}</td>
                          <td onClick={() => handleViewClick(id, entities[id])}>{entities[id].coffee}</td>
                          <td onClick={() => handleViewClick(id, entities[id])}>{entities[id].consumption}</td>
                          <td>
                            {
                              entities[id].visits?.length ? (
                                <>
                                  <VisitList
                                    id={id}
                                    date={entities[id].visits[entities[id].visits.length - 1].visitDate}
                                    storeName={entities[id].storeTitle}
                                    visits={entities[id].visits}
                                  />
                                </>
                              ) : (
                                <VisitForm id={id} storeName={entities[id].storeTitle} />
                              )
                            }
                          </td>
                          <td>
                            {
                              entities[id].samplings?.length ? (
                                <>
                                  <SamplingsList
                                    id={id}
                                    date={entities[id].samplings[entities[id].samplings.length - 1].samplingDate}
                                    storeName={entities[id].storeTitle}
                                    samplings={entities[id].samplings}
                                  />
                                </>
                              ) : (
                                <SamplingForm
                                  id={id}
                                  storeName={entities[id].storeTitle}
                                />
                              )
                            }
                          </td>
                          <td>
                            <Dropdown>
                              <Dropdown.Toggle className="secondary-btn">
                                <FontAwesomeIcon icon={faBars} />
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item onClick={() => handleShowScheduleModal(entities[id], 'ΕΠΙΣΚΕΨΗ')}>Προγραμματισμός Επίσκεψης</Dropdown.Item>
                                <Dropdown.Item onClick={() => handleShowScheduleModal(entities[id], 'ΔΕΙΓΜΑΤΙΣΜΟΣ')}>Προγραμματισμός Δειγματισμού</Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  <div>
                    <Button className="secondary-btn" onClick={prevPage} disabled={currentPage === 1}>
                      Προηγούμενη Σελίδα
                    </Button>
                    <span> Σελίδα {currentPage} από {totalPages} </span>
                    <Button className="secondary-btn" onClick={nextPage} disabled={currentPage === totalPages}>
                      Επόμενη Σελίδα
                    </Button>
                  </div>
                </div>
              </div>
            </Col>
          </Row>

          {showViewModal && (
            <StoreCard
              id={selectedId}
              selectedStore={selectedStore}
              show={showViewModal}
              handleCloseView={handleViewModalClose}
            />
          )}

          {showScheduleModal && (
            <AddToSchedule
              selectedStore={selectedScheduleStore}
              scheduleEntryType={scheduleEntryType}
              show={showScheduleModal}
              handleCloseView={handleCloseScheduleModal}
            />
          )}
        </Container>
      </>
    )
  }

  return content
}

export default CoffeeStoresList