import { faMinus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Alert, Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { useUpdateEquipmentMutation } from './clientsApiSlice';

const EquipmentModal = ({ equipment, id, shopId }) => {

    const [show, setShow] = useState(false);
    const [isEditable, setIsEditable] = useState(false);
    const [showSuccessMsg, setShowSuccessMsg] = useState(false)

    const [updateEquipment, {
        isSuccess
    }] = useUpdateEquipmentMutation()

    // Initialize formData with an array of equipment items
    const [formData, setFormData] = useState(
        equipment.map((item) => ({
            type: item.type || "",
            brand: item.brand || "",
            model: item.model || "",
            serial_number: item.serial_number || ""
        }))
    );

    // Handle opening and closing of the modal
    const handleShow = () => setShow(true);
    const handleClose = () => {
        setShow(false);
        setShowSuccessMsg(false)
        setIsEditable(!isEditable);
    }

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        // Handle form submission logic here
        try {
            // console.log('submitting... ', shopId)
            const updateData = { ...formData, shopId };
            await updateEquipment({ id, updateData }).unwrap();
            setShowSuccessMsg(true)
            // console.log("Client updated successfully!");
        } catch (error) {
            console.error("Failed to update client:", error);
        }
    };

    // Handle input change
    const handleInputChange = (index, field, value) => {
        const updatedFormData = [...formData];
        updatedFormData[index][field] = value;
        setFormData(updatedFormData);
    };

    // Handle toggle between edit and save
    const handleEditToggle = () => {
        setIsEditable(!isEditable);
    };

    // Handle remove specific equipment
    const handleRemoveEquipment = (index) => {
        const updatedFormData = [...formData];
        updatedFormData.splice(index, 1); // Remove the item at the specified index
        setFormData(updatedFormData);
    };

    const handleAddEquipment = () => {
        const newEquipment = {
            type: '',
            manufacturer: '',
            model: '',
            serial_number: ''
        };
        setFormData([...formData, newEquipment]);
    };

    // Reset form
    const handleReset = () => {
        setShowSuccessMsg(false)
    };

    return (
        <>
            {/* Button that triggers the modal */}
            <Button className="primary-btn" onClick={handleShow}>Προβολή Εξοπλισμού</Button>

            {/* Modal */}
            <Modal show={show} onHide={handleClose} size='xl'>
                <Modal.Header closeButton>
                    <Modal.Title>Εξοπλισμός</Modal.Title>
                </Modal.Header>
                {/* If the request is successful, show success message */}
                {isSuccess && showSuccessMsg ? (
                    <>
                        {/* If form is submitted, show the success message*/}
                        <Modal.Body>
                            <Alert variant="success">
                                O Εξοπλισμός ενημερώθηκε επιτυχώς!
                            </Alert>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button className='primary-btn' onClick={handleReset}>Επεξεργασία Συνταγών</Button>
                            <Button className='cancel-btn' onClick={handleClose}>Κλείσιμο</Button>
                        </Modal.Footer>
                    </>
                ) : (
                    <Form onSubmit={handleSubmit}>
                        <Modal.Body>
                            {/* Display equipment content and make it editable if needed */}
                            {formData.map((item, index) => (
                                <Row className="mb-3" key={index}>
                                    <Form.Group as={Col} xs={12} md={3} controlId={`type-${index}`}>
                                        <Form.Label>Τύπος</Form.Label>
                                        <Form.Select
                                            name="type"
                                            value={item.type}
                                            onChange={(e) => handleInputChange(index, 'type', e.target.value)}
                                            disabled={!isEditable}
                                            required
                                        >
                                            {/* Dropdown options */}
                                            <option value="">Επιλογή Τύπου</option>
                                            <option value="Espresso Machine">Espresso Machine</option>
                                            <option value="Grinder">Grinder</option>
                                        </Form.Select>

                                    </Form.Group>
                                    <Form.Group as={Col} xs={12} md={3} controlId={`brand-${index}`}>
                                        <Form.Label>Brand</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="brand"
                                            value={item.brand}
                                            onChange={(e) => handleInputChange(index, 'brand', e.target.value)}
                                            disabled={!isEditable}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} xs={12} md={3} controlId={`model-${index}`}>
                                        <Form.Label>Model</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="model"
                                            value={item.model}
                                            onChange={(e) => handleInputChange(index, 'model', e.target.value)}
                                            disabled={!isEditable}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} xs={12} md={2} controlId={`serial_number-${index}`}>
                                        <Form.Label>Serial Number</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="serial_number"
                                            value={item.serial_number}
                                            onChange={(e) => handleInputChange(index, 'serial_number', e.target.value)}
                                            disabled={!isEditable}
                                        />
                                    </Form.Group>
                                    <Col xs={12} md={1} className="text-end">
                                        <Button
                                            variant="danger"
                                            onClick={() => handleRemoveEquipment(index)}
                                            disabled={!isEditable}
                                            style={{ marginTop: "30px" }}
                                        >
                                            <FontAwesomeIcon icon={faMinus} />
                                        </Button>
                                    </Col>
                                </Row>
                            ))}
                            {/* Add Equipment Button */}
                            {isEditable && (
                                <div className="text-center mt-3">
                                    <Button variant="success" className='add-btn' onClick={handleAddEquipment}>
                                        Προσθήκη Εξοπλισμού
                                    </Button>
                                </div>
                            )}

                        </Modal.Body>

                        <Modal.Footer>
                            {/* Toggle buttons */}
                            {!isEditable ? (
                                <Button variant="primary" className='primary-btn' onClick={handleEditToggle}>
                                    Επεξεργασία
                                </Button>
                            ) : (
                                <>
                                    <Button variant="secondary" className='cancel-btn' onClick={handleClose}>
                                        Κλείσιμο
                                    </Button>
                                    {/* Submit button for the form */}
                                    <Button type="submit" className='primary-btn'>
                                        Αποθήκευση
                                    </Button>
                                </>
                            )}
                        </Modal.Footer>
                    </Form>
                )}
            </Modal>
        </>
    )
}

export default EquipmentModal